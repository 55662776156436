import React, { useState, useEffect } from "react";
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import FeedbackIcon from "@mui/icons-material/Feedback";
import toast from "react-hot-toast";
import useSignsData from "../../hooks/useSignsData";
import ConstructionIcon from "@mui/icons-material/Construction";
import FeedbackModal from "./FeedbackModal";

const ModeButton = ({ onClick, isEditMode, className, searchBarWidth }) => {
  const [open, setOpen] = useState(false);
  const { refreshData } = useSignsData();
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const [refreshCountdown, setRefreshCountdown] = useState(0);
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);

  // Xử lý sự kiện click cho nút chỉnh sửa
  const handleEditClick = () => {
    onClick();
    setOpen(false);
  };

  // Xử lý sự kiện click cho nút làm mới
  const handleRefreshClick = () => {
    if (isRefreshDisabled) return;

    toast.promise(
      refreshData().then((refreshedData) => {
        return refreshedData;
      }),
      {
        loading: "Đang làm mới toàn bộ dữ liệu...",
        success: "Tất cả dữ liệu đã được làm mới thành công",
        error: (err) => `Lỗi khi làm mới dữ liệu: ${err.message}`,
      }
    );
    setOpen(false);
    setIsRefreshDisabled(true);
    setRefreshCountdown(30); // Đặt thời gian khóa là 30 giây
  };

  // Xử lý sự kiện click cho nút góp ý
  const handleFeedbackClick = () => {
    setIsFeedbackModalVisible(true);
    setOpen(false);
  };

  // Xử lý đếm ngược thời gian khóa
  useEffect(() => {
    let timer;
    if (isRefreshDisabled && refreshCountdown > 0) {
      timer = setInterval(() => {
        setRefreshCountdown((prevCount) => prevCount - 1);
      }, 1000);
    } else if (refreshCountdown === 0) {
      setIsRefreshDisabled(false);
    }
    return () => clearInterval(timer);
  }, [isRefreshDisabled, refreshCountdown]);

  return (
    <>
      <SpeedDial
        ariaLabel="Chức năng trang chủ"
        className={className}
        icon={
          <SpeedDialIcon icon={<ConstructionIcon />} openIcon={<CloseIcon />} />
        }
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        direction="right"
        sx={{
          position: "absolute",
          "& .MuiSpeedDial-fab": {
            width: searchBarWidth,
            height: searchBarWidth,
            borderRadius: "4px",
          },
        }}
        FabProps={{
          sx: {
            bgcolor: isEditMode ? "success.main" : "primary.main",
            "&:hover": {
              bgcolor: isEditMode ? "success.dark" : "primary.dark",
            },
          },
        }}
      >
        <SpeedDialAction
          icon={isEditMode ? <CloseIcon /> : <EditIcon />}
          tooltipTitle={
            isEditMode ? "Thoát chế độ chỉnh sửa" : "Chế độ chỉnh sửa"
          }
          onClick={handleEditClick}
          sx={{
            width: searchBarWidth,
            height: searchBarWidth,
            borderRadius: "4px",
          }}
          arrow
        />
        <SpeedDialAction
          icon={<RefreshIcon />}
          tooltipTitle={
            isRefreshDisabled
              ? `Làm mới dữ liệu (${refreshCountdown}s)`
              : "Làm mới toàn bộ dữ liệu"
          }
          onClick={handleRefreshClick}
          sx={{
            width: searchBarWidth,
            height: searchBarWidth,
            borderRadius: "4px",
            opacity: isRefreshDisabled ? 0.5 : 1,
          }}
          arrow
        />
        <SpeedDialAction
          icon={<FeedbackIcon />}
          tooltipTitle="Gửi góp ý"
          onClick={handleFeedbackClick}
          sx={{
            width: searchBarWidth,
            height: searchBarWidth,
            borderRadius: "4px",
          }}
          arrow
        />
      </SpeedDial>
      <FeedbackModal
        open={isFeedbackModalVisible}
        onClose={() => setIsFeedbackModalVisible(false)}
      />
    </>
  );
};

export default ModeButton;

import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBFDxmtt5Km9uBEKKjs-Fkp22uXS3Ej_DA",
  authDomain: "tsm-chat-94f9a.firebaseapp.com",
  databaseURL:
    "https://tsm-chat-94f9a-default-rtdb.asia-southeast1.firebasedatabase.app/",
  projectId: "tsm-chat-94f9a",
  storageBucket: "tsm-chat-94f9a.appspot.com",
  messagingSenderId: "7551926405",
  appId: "1:7551926405:web:5fdffdfb7afe4db307e9ec",
  measurementId: "G-85LJ3FBQTS",
};

// Khởi tạo Firebase
const app = initializeApp(firebaseConfig);

// Lấy tham chiếu đến Realtime Database
const database = getDatabase(app);

// Lấy tham chiếu đến Authentication
const auth = getAuth(app);

// Lấy tham chiếu đến Storage
const storage = getStorage(app); // Thêm dòng này

export { app, database, auth, storage }; // Thêm storage vào export

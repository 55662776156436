import React from "react";

const Loading = ({ message = "Đang tải..." }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex flex-col items-center justify-center z-50">
      <div className="relative">
        <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
        <div
          className="w-16 h-16 border-t-4 border-purple-500 border-solid rounded-full animate-spin absolute top-0 left-0"
          style={{ animationDirection: "reverse", animationDuration: "1.5s" }}
        ></div>
      </div>
      <p className="mt-4 text-xl text-white font-semibold">{message}</p>
    </div>
  );
};

export default Loading;

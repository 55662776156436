import React, { useState, useEffect } from "react";
import { CContainer } from "@coreui/react";
import { useNavigate, Routes, Route } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import UserSidebar from "./UserSidebar";
import UserHeader from "./UserHeader";
import UserBreadcrumb from "./UserBreadcrumb";
import UserProfile from "./UserProfile";
import UserMapSigns from "./UserMapSigns";
import UserFeedback from "./UserFeedback";
import "@coreui/coreui/dist/css/coreui.min.css";

const UserPanel = () => {
  const [sidebarOpen, setSidebarOpen] = useState(() => {
    const savedState = localStorage.getItem("userSidebarOpen");
    return savedState !== null ? JSON.parse(savedState) : true;
  });
  const { isLoggedIn, isInitialized } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("userSidebarOpen", JSON.stringify(sidebarOpen));
  }, [sidebarOpen]);

  useEffect(() => {
    if (isInitialized && !isLoggedIn) {
      navigate("/login");
    }
  }, [isInitialized, isLoggedIn, navigate]);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <UserSidebar isOpen={sidebarOpen} />
      <div className={`flex-1 flex flex-col transition-all duration-300 `}>
        <UserHeader toggleSidebar={toggleSidebar} />
        <UserBreadcrumb />
        <div className="flex-1 overflow-auto p-4">
          <CContainer fluid className="h-full p-0">
            <Routes>
              <Route path="/me" element={<UserProfile />} />
              <Route path="/mapsigns" element={<UserMapSigns />} />
              <Route path="/feedbacks" element={<UserFeedback />} />
              <Route path="*" element={<UserProfile />} />
            </Routes>
          </CContainer>
        </div>
      </div>
    </div>
  );
};

export default UserPanel;

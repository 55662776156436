import React, { useMemo } from "react";
import DistanceSignComponent from "./DistanceSignComponent";
import DistanceEffectSign from "./DistanceEffectSign";
import HoursDaysSignComponent from "./HoursDaysSignComponent";

// Chuyển hàm này ra ngoài component để tránh tạo lại mỗi lần render
const getDayOfWeek = (day) => {
  const days = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];
  return days[day % 7];
};

const SubsignPreview = React.memo(
  ({ subsign, icons, width = 200, height = 60 }) => {
    const content = useMemo(() => {
      if (subsign?.content_type === "Icon" && subsign?.icon_id) {
        const selectedIcon = icons.find(
          (icon) => icon.id.toString() === subsign.icon_id.toString()
        );
        if (selectedIcon) {
          return (
            <img
              src={`/media/icons/${selectedIcon.image_path}`}
              alt={selectedIcon.icon_name}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          );
        }
      }
      return subsign?.content || "";
    }, [subsign, icons]);

    const commonProps = useMemo(
      () => ({
        width,
        height,
        content,
        backgroundColor: subsign?.has_background
          ? subsign.background_color
          : "white",
        borderColor: subsign?.has_border ? subsign.border_color : "#000000",
        contentColor: subsign?.text_color || "black",
        isIcon: subsign?.content_type === "Icon",
      }),
      [subsign, width, height, content]
    );

    const hour_range_count = useMemo(
      () => (subsign?.has_time_restriction ? subsign.hour_range_count || 1 : 0),
      [subsign]
    );

    const day_range_count = useMemo(
      () => (subsign?.has_date_restriction ? subsign.day_range_count || 1 : 0),
      [subsign]
    );

    const hour_ranges = useMemo(() => {
      if (subsign?.has_time_restriction) {
        return Array.isArray(subsign.hour_ranges)
          ? subsign.hour_ranges
          : JSON.parse(subsign.hour_ranges || "[]");
      }
      return [];
    }, [subsign]);

    const day_ranges = useMemo(() => {
      if (subsign?.has_date_restriction) {
        const rawRanges = Array.isArray(subsign.day_ranges)
          ? subsign.day_ranges
          : JSON.parse(subsign.day_ranges || "[]");
        return rawRanges.map((range) =>
          range.map((day) => {
            if (typeof day === "number" || !isNaN(parseInt(day))) {
              return getDayOfWeek(parseInt(day));
            }
            return day;
          })
        );
      }
      return [];
    }, [subsign]);

    if (!subsign) {
      return null;
    }

    switch (subsign.subsign_type_id.toString()) {
      case "1":
        return <DistanceSignComponent {...commonProps} />;
      case "2":
        return <DistanceEffectSign {...commonProps} />;
      case "7":
        return (
          <HoursDaysSignComponent
            hour_range_count={hour_range_count}
            day_range_count={day_range_count}
            hour_ranges={hour_ranges}
            day_ranges={day_ranges}
            backgroundColor={commonProps.backgroundColor}
            borderColor={commonProps.borderColor}
            contentColor={commonProps.contentColor}
            width={width}
            height={height}
          />
        );
      default:
        return null;
    }
  }
);

export default SubsignPreview;

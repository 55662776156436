import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import store from "./store";
import AppContent from "./components/AppContent";

// Tên dự án mới
const PROJECT_NAME = "Cộng đồng chia sẻ biển báo giao thông Việt Nam";

function App() {
  return (
    <Provider store={store}>
      <Router>
        {/* Thêm các thẻ meta chuẩn SEO */}
        <Helmet>
          <title>{PROJECT_NAME}</title>
          <meta
            name="description"
            content="Hệ thống quản lý và tra cứu biển báo giao thông hiện đại của Việt Nam, cung cấp thông tin chính xác và cập nhật về các biển báo trên đường."
          />
          <meta
            name="keywords"
            content="biển báo giao thông, quản lý biển báo, an toàn đường bộ, giao thông, hệ thống quản lý, Việt Nam, cộng đồng"
          />
          <meta property="og:title" content={PROJECT_NAME} />
          <meta
            property="og:description"
            content="Hệ thống quản lý và tra cứu biển báo giao thông hiện đại của Việt Nam, cung cấp thông tin chính xác và cập nhật về các biển báo trên đường."
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={PROJECT_NAME} />
          <meta
            name="twitter:description"
            content="Hệ thống quản lý và tra cứu biển báo giao thông hiện đại của Việt Nam, cung cấp thông tin chính xác và cập nhật về các biển báo trên đường."
          />
        </Helmet>
        <AppContent />
      </Router>
    </Provider>
  );
}

export default App;

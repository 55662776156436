import React, {
  useEffect,
  lazy,
  Suspense,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useAuth } from "../hooks/useAuth";
import { usePermission } from "../hooks/usePermission";
import NotFound from "../errorpages/404";
import UserPanel from "./users/userpanel/UserPanel";
import Loading from "./common/Loading";
import HomeMenu from "./homepage/HomeMenu";
import { useGeolocation } from "../hooks/useGeolocation";
import Chatbox from "./chatbox/Chatbox";
import { useRenderLoopDetection } from "../hooks/looptest/useRenderLoopDetection";

// Lazy loading các component chính để tối ưu hiệu suất
const Map = lazy(() => import("./map/Map"));
const EditorMap = lazy(() => import("./map/EditorMap"));
const AdminPanel = lazy(() => import("./admin/AdminPanel"));
const Register = lazy(() => import("./users/Register"));
const AuthForm = lazy(() => import("./users/AuthForm"));

// Component bảo vệ route, kiểm tra đăng nhập và quyền truy cập
const ProtectedRoute = ({ children, requiredPermission = null }) => {
  const { isLoggedIn, isLoading, isAuthChecked } = useAuth();
  const { permissions, isLoading: permissionsLoading } = usePermission();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthChecked && !isLoggedIn) {
      navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`, {
        replace: true,
      });
    }
  }, [isAuthChecked, isLoggedIn, navigate, location.pathname]);

  if (isLoading || permissionsLoading) {
    return <Loading message="Đang xác thực" />;
  }

  if (!isLoggedIn) {
    return null;
  }

  // Kiểm tra quyền truy cập
  if (requiredPermission && !permissions[requiredPermission]) {
    return <Navigate to="/" replace />;
  }

  return children;
};

// Component chính của ứng dụng
const AppContent = () => {
  // State và hooks
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const { position: userPosition } = useGeolocation();
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.pathname === "/editor";
  const mapRef = useRef(null);
  const shouldShowChatbox =
    location.pathname === "/" || location.pathname === "/editor";

  // Trigger kiểm tra loop
  useRenderLoopDetection();

  // Sử dụng useAuth và usePermission hooks
  const { checkAuthStatus } = useAuth();

  // Kiểm tra xác thực khi component mount
  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const handleSearchFocus = useCallback(() => {
    setSearchTerm("");
  }, []);

  const handleSearchStateChange = useCallback(() => {}, []);

  const handleSearchSelect = useCallback((location) => {
    setSelectedLocation(location);
    if (mapRef.current) {
      mapRef.current.flyTo([location.lat, location.lng], 18, {
        duration: 1,
        easeLinearity: 0.2,
      });
    }
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearchTerm("");
    setSelectedLocation(null);
  }, []);

  const handleSearchTermChange = useCallback((newTerm) => {
    if (typeof newTerm === "object" && newTerm !== null && "label" in newTerm) {
      setSearchTerm(newTerm.label);
    } else {
      setSearchTerm(newTerm);
    }
  }, []);

  const handleEditButtonClick = useCallback(() => {
    navigate(isEditMode ? "/" : "/editor");
  }, [navigate, isEditMode]);

  const handleLocationSelect = useCallback((location) => {
    setSelectedLocation(location);
  }, []);

  const handleFilterChange = useCallback((newFilters) => {
    setSelectedFilters(newFilters);
  }, []);

  // Xác định xem có nên hiển thị HomeMenu hay không
  const shouldShowHomeMenu =
    location.pathname === "/" || location.pathname === "/editor";

  return (
    <div className="app-container relative">
      <Toaster position="top-center" />
      {shouldShowChatbox && <Chatbox />}
      {shouldShowHomeMenu && (
        <HomeMenu
          onSearchFocus={handleSearchFocus}
          userPosition={userPosition}
          onSearchStateChange={handleSearchStateChange}
          selectedLocation={selectedLocation}
          onSearchSelect={handleSearchSelect}
          onSearchClear={handleSearchClear}
          searchTerm={searchTerm}
          onSearchTermChange={handleSearchTermChange}
          onEditButtonClick={handleEditButtonClick}
          isEditMode={isEditMode}
          mapRef={mapRef}
          onLocationSelect={handleLocationSelect}
          onFilterChange={handleFilterChange}
        />
      )}

      <Routes>
        {/* Route cho trang chính */}
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading message="Đang tải bản đồ" />}>
              <Map
                searchTerm={searchTerm}
                selectedLocation={selectedLocation}
                onSearchSelect={handleSearchSelect}
                onSearchClear={handleSearchClear}
                mapRef={mapRef}
                onLocationSelect={handleLocationSelect}
                onSearchTermChange={handleSearchTermChange}
                selectedFilters={selectedFilters}
              />
            </Suspense>
          }
        />

        {/* Route cho chế độ chỉnh sửa */}
        <Route
          path="/editor/*"
          element={
            <ProtectedRoute requiredPermission="canAddSign">
              <Suspense fallback={<Loading message="Đang tải bản đồ" />}>
                <EditorMap
                  searchTerm={searchTerm}
                  selectedLocation={selectedLocation}
                  onSearchSelect={handleSearchSelect}
                  onSearchClear={handleSearchClear}
                  mapRef={mapRef}
                  onLocationSelect={handleLocationSelect}
                  onSearchTermChange={handleSearchTermChange}
                  selectedFilters={selectedFilters}
                />
              </Suspense>
            </ProtectedRoute>
          }
        />

        {/* Route cho trang quản trị */}
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute requiredPermission="canAccessAdminPanel">
              <Suspense
                fallback={<Loading message="Đang tải bảng điều khiển" />}
              >
                <AdminPanel />
              </Suspense>
            </ProtectedRoute>
          }
        />

        <Route
          path="/login"
          element={
            <Suspense fallback={<Loading message="Đăng nhập" />}>
              <AuthForm />
            </Suspense>
          }
        />
        <Route
          path="/register"
          element={
            <Suspense fallback={<Loading message="Đăng ký" />}>
              <Register />
            </Suspense>
          }
        />
        <Route
          path="/user/*"
          element={
            <ProtectedRoute>
              <UserPanel />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default AppContent;

import CryptoJS from "crypto-js";

const API_KEY = process.env.REACT_APP_API_ENCRYPTION_KEY;

export const decryptData = (encryptedData) => {
  if (!API_KEY) throw new Error("Khóa mã hóa chưa được thiết lập");
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, API_KEY);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString);
  } catch (error) {
    console.error("Lỗi giải mã:", error);
    throw error;
  }
};

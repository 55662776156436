import React, { useState, useCallback, useRef, useEffect } from "react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import debounce from "lodash/debounce";
import { getMapSignById } from "../../services/api";

// Định nghĩa các tùy chọn filter
const filterOptions = [
  { value: 11, label: "Cấm đỗ", isFilter: true },
  { value: 12, label: "Cấm dừng cấm đỗ", isFilter: true },
  { value: 10, label: "Cấm ôtô", isFilter: true },
];

const animatedComponents = makeAnimated();

const SearchBar = ({
  onFocus,
  userPosition,
  onSearchStateChange,
  onSelect,
  onClear,
  mapRef,
  onLocationSelect,
  onFilterChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const searchRef = useRef(null);
  const provider = new OpenStreetMapProvider({
    params: {
      "accept-language": "vi",
      countrycodes: "vn",
    },
  });

  // Hàm tính khoảng cách giữa hai điểm địa lý
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Bán kính trái đất (km)
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  // Hàm tìm kiếm thực tế
  const performSearch = async (value) => {
    if (value.trim() === "") {
      return filterOptions;
    }

    try {
      // Tìm kiếm địa điểm
      const locationResults = await provider.search({ query: value });
      let locationOptions = locationResults.slice(0, 5).map((result) => ({
        value: { lat: result.y, lng: result.x },
        label: result.label,
        isLocation: true,
      }));

      if (userPosition) {
        locationOptions = locationOptions.map((option) => ({
          ...option,
          distance: calculateDistance(
            userPosition[0],
            userPosition[1],
            option.value.lat,
            option.value.lng
          ),
        }));
        locationOptions.sort((a, b) => a.distance - b.distance);
      }

      // Tìm kiếm biển báo theo ID
      if (/^\d+$/.test(value)) {
        try {
          const signResult = await getMapSignById(parseInt(value));
          if (signResult) {
            locationOptions.unshift({
              value: {
                lat: signResult.latitude,
                lng: signResult.longitude,
                id: signResult.id,
              },
              label: `Biển báo ID: ${signResult.id}`,
              isLocation: true,
              isSign: true,
            });
          }
        } catch (error) {
          console.error("Lỗi khi tìm kiếm biển báo:", error);
        }
      }

      const matchingFilters = filterOptions.filter((filter) =>
        filter.label.toLowerCase().includes(value.toLowerCase())
      );

      return [...locationOptions, ...matchingFilters];
    } catch (error) {
      console.error("Lỗi tìm kiếm:", error);
      return [];
    }
  };

  // Sử dụng useCallback và debounce để tạo một hàm tìm kiếm trễ
  const debouncedSearch = useCallback(
    debounce(async (inputValue, callback) => {
      const results = await performSearch(inputValue);
      callback(results);
    }, 600),
    [userPosition]
  );

  // Hàm tải các tùy chọn cho AsyncSelect
  const loadOptions = (inputValue, callback) => {
    setInputValue(inputValue);
    debouncedSearch(inputValue, callback);
  };

  // Xử lý sự kiện thay đổi giá trị của Select
  const handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === "select-option") {
      if (newValue[newValue.length - 1]?.isLocation) {
        const location = newValue[newValue.length - 1];
        onLocationSelect(location.value);
        onSelect({ label: location.label, ...location.value });
        setSelectedOptions(newValue.filter((option) => !option.isLocation));
      } else {
        setSelectedOptions(newValue);
        onFilterChange(
          newValue
            .filter((option) => option.isFilter)
            .map((filter) => filter.value)
        );
      }
    } else if (
      actionMeta.action === "remove-value" ||
      actionMeta.action === "pop-value"
    ) {
      setSelectedOptions(newValue);
      onFilterChange(
        newValue
          .filter((option) => option.isFilter)
          .map((filter) => filter.value)
      );
    } else if (actionMeta.action === "clear") {
      setSelectedOptions([]);
      onFilterChange([]);
      onClear();
    }
  };

  // Xử lý sự kiện khi input thay đổi
  const handleInputChange = (newValue, actionMeta) => {
    if (
      actionMeta.action !== "input-blur" &&
      actionMeta.action !== "menu-close"
    ) {
      setInputValue(newValue);
    }
  };

  return (
    <div className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl">
      <AsyncSelect
        ref={searchRef}
        isMulti
        cacheOptions
        defaultOptions={filterOptions}
        loadOptions={loadOptions}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onFocus={() => {
          onFocus();
          onSearchStateChange(true);
        }}
        inputValue={inputValue}
        placeholder="Địa điểm, ID biển báo, chọn bộ lọc..."
        noOptionsMessage={() => "Không tìm thấy kết quả"}
        value={selectedOptions}
        formatOptionLabel={({ label, distance, isLocation, isSign }) => (
          <div className="flex items-center">
            {isSign && <span className="mr-2">🚦</span>}
            <div>
              <div>{label}</div>
              {isLocation && distance && (
                <div className="text-xs text-gray-500">
                  {distance.toFixed(1)} km
                </div>
              )}
            </div>
          </div>
        )}
        components={animatedComponents}
        isClearable
        classNamePrefix="react-select"
        className="react-select-container"
        styles={{
          control: (provided, state) => ({
            ...provided,
            minWidth: "200px",
            maxWidth: "100%",
            borderColor: state.isFocused ? "#3b82f6" : "#e5e7eb", // Màu viền khi focus và không focus
            boxShadow: state.isFocused ? "0 0 0 1px #3b82f6" : "none", // Tạo hiệu ứng "glow" khi focus
            "&:hover": {
              borderColor: state.isFocused ? "#3b82f6" : "#d1d5db",
            },
          }),
          input: (provided) => ({
            ...provided,
            color: "#374151", // Màu chữ khi nhập
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "#9ca3af", // Màu chữ placeholder
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#e5e7eb" : "white",
            color: "#374151",
            "&:active": {
              backgroundColor: "#dbeafe",
            },
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: "#e5e7eb",
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: "#374151",
          }),
          multiValueRemove: (provided) => ({
            ...provided,
            color: "#4b5563",
            "&:hover": {
              backgroundColor: "#d1d5db",
              color: "#1f2937",
            },
          }),
        }}
      />
    </div>
  );
};

export default SearchBar;

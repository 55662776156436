import React from "react";
import { useLocation } from "react-router-dom";
import HomeAvatar from "./HomeAvatar";
import SearchBar from "./SearchBar";
import ModeButton from "./ModeButton";

const HomeMenu = ({
  onSearchFocus,
  userPosition,
  onSearchStateChange,
  selectedLocation,
  onSearchSelect,
  onSearchClear,
  searchTerm,
  onSearchTermChange,
  onEditButtonClick,
  isEditMode,
  mapRef,
  onLocationSelect,
  onFilterChange,
}) => {
  const location = useLocation();
  const showEditButton =
    location.pathname === "/" || location.pathname === "/editor";

  return (
    <div className="absolute top-0 left-0 right-0 z-20 p-3">
      <div className="flex flex-col md:flex-row items-start md:items-center">
        <HomeAvatar className="mb-4 md:mb-0 md:mr-4" />
        <div className="flex-grow flex flex-col md:flex-row md:items-center w-full md:w-auto">
          <div className="w-full md:w-64 lg:w-80 mb-4 md:mb-0">
            <SearchBar
              onFocus={onSearchFocus}
              userPosition={userPosition}
              onSearchStateChange={onSearchStateChange}
              selectedLocation={selectedLocation}
              onSelect={onSearchSelect}
              onClear={onSearchClear}
              searchTerm={searchTerm}
              onSearchTermChange={onSearchTermChange}
              mapRef={mapRef}
              onLocationSelect={onLocationSelect}
              onFilterChange={onFilterChange}
            />
          </div>
          {showEditButton && (
            <div className="w-full md:w-auto md:ml-2 md:-mt-20">
              <ModeButton
                onClick={onEditButtonClick}
                isEditMode={isEditMode}
                className="w-full md:w-auto "
                searchBarWidth={41}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeMenu;

import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, logoutSuccess } from "../store/authSlice";
import { getUserById } from "../services/api";
import {
  saveToIndexedDB,
  getFromIndexedDB,
  removeFromIndexedDB,
} from "../utils/indexedDB";

export const useAuth = (onAuthStateChange) => {
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  // Hàm xử lý đăng xuất
  const handleLogout = useCallback(async () => {
    dispatch(logoutSuccess());
    await removeFromIndexedDB("token");
    await removeFromIndexedDB("userId");
    await removeFromIndexedDB("userData");
  }, [dispatch]);

  // Kiểm tra trạng thái xác thực
  const checkAuthStatus = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = await getFromIndexedDB("token");
      const userId = await getFromIndexedDB("userId");
      const wasLoggedIn = isLoggedIn;

      if (!token || !userId) {
        // Nếu không có token hoặc userId, đăng xuất người dùng
        await handleLogout();
      } else {
        // Lấy userData từ IndexedDB thay vì gọi API
        const userData = await getFromIndexedDB("userData");
        if (userData) {
          // Nếu có userData, cập nhật trạng thái đăng nhập
          dispatch(loginSuccess(userData));
        } else {
          // Nếu không có userData, đăng xuất người dùng
          console.error("Không tìm thấy dữ liệu người dùng trong IndexedDB");
          await handleLogout();
        }
      }

      // Gọi callback nếu được cung cấp và trạng thái đăng nhập thay đổi
      if (
        typeof onAuthStateChange === "function" &&
        wasLoggedIn !== isLoggedIn
      ) {
        onAuthStateChange(isLoggedIn);
      }
    } catch (error) {
      console.error("Lỗi khi kiểm tra trạng thái xác thực:", error);
      await handleLogout();
    } finally {
      setIsLoading(false);
      setIsAuthChecked(true);
    }
  }, [dispatch, isLoggedIn, onAuthStateChange, handleLogout]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  // Xử lý đăng nhập
  const login = useCallback(
    async (token, userId) => {
      try {
        setIsLoading(true);
        // Lưu token và userId vào IndexedDB
        await saveToIndexedDB("token", token);
        await saveToIndexedDB("userId", userId);

        // Lấy thông tin người dùng từ API
        const userData = await getUserById(userId);
        // Lưu thông tin người dùng vào IndexedDB
        await saveToIndexedDB("userData", userData);

        // Cập nhật trạng thái đăng nhập trong Redux store
        dispatch(loginSuccess(userData));
        if (typeof onAuthStateChange === "function") {
          onAuthStateChange(true);
        }
      } catch (error) {
        console.error("Lỗi khi đăng nhập:", error);
        await handleLogout();
        if (typeof onAuthStateChange === "function") {
          onAuthStateChange(false);
        }
      } finally {
        setIsLoading(false);
        setIsAuthChecked(true);
      }
    },
    [dispatch, onAuthStateChange, handleLogout]
  );

  // Xử lý đăng xuất
  const logout = useCallback(async () => {
    setIsLoading(true);
    await handleLogout();
    setIsLoading(false);
    setIsAuthChecked(true);
    if (typeof onAuthStateChange === "function") {
      onAuthStateChange(false);
    }
  }, [onAuthStateChange, handleLogout]);

  return {
    isLoggedIn,
    user,
    isLoading,
    isAuthChecked,
    login,
    logout,
    checkAuthStatus,
  };
};

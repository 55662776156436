import React from "react";

// Định nghĩa màu chính của ứng dụng
const primaryColor = {
  start: "#6a11cb",
  end: "#2575fc",
};

// Định nghĩa các styles cho component
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    width: "100%",
    background: `linear-gradient(to right, ${primaryColor.start}, ${primaryColor.end})`,
    fontFamily: "Helvetica, Courier, monospace", // Thêm font Courier New
  },
  messageContainer: {
    backgroundColor: "white",
    padding: "2rem",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    maxWidth: "400px",
  },
  header: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: primaryColor.start,
    marginBottom: "1rem",
  },
  description: {
    fontSize: "1rem",
    color: "#333",
    marginBottom: "1.5rem",
  },
  button: {
    padding: "0.5rem 1rem",
    background: `linear-gradient(to right, ${primaryColor.start}, ${primaryColor.end})`,
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontFamily: "Helvetica, Courier, monospace", // Thêm font Courier New cho button
  },
};

const NotFound = () => {
  return (
    <div style={styles.container}>
      <div style={styles.messageContainer}>
        <h1 style={styles.header}>404</h1>
        <p style={styles.description}>Trang không tồn tại</p>
        <button
          style={styles.button}
          onClick={() => (window.location.href = "/")}
        >
          Quay về trang chủ
        </button>
      </div>
    </div>
  );
};

export default NotFound;

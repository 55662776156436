import { useLayoutEffect, useRef } from "react";

const MAX_RENDER_COUNT = 100;
const RENDER_INTERVAL = 1000;

export const useRenderLoopDetection = () => {
  const renderCountRef = useRef(0);
  const lastResetTimeRef = useRef(Date.now());

  useLayoutEffect(() => {
    if (process.env.REACT_APP_ENABLE_LOOP_TEST === "true") {
      renderCountRef.current += 1;
      const now = Date.now();

      if (now - lastResetTimeRef.current > RENDER_INTERVAL) {
        if (renderCountRef.current > MAX_RENDER_COUNT) {
          console.error(
            `Phát hiện render quá mức: ${renderCountRef.current} lần trong ${RENDER_INTERVAL}ms`
          );
          throw new Error("Render loop detected");
        }
        renderCountRef.current = 0;
        lastResetTimeRef.current = now;
      }
    }
  });
};

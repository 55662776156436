import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Grid,
  CircularProgress,
  Divider,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getFromIndexedDB } from "../../utils/indexedDB";
import { createUserFeedback, getMapSignById } from "../../services/api";
import toast from "react-hot-toast";

// Schema validation cho form
const schema = yup.object().shape({
  name: yup.string().required("Họ và tên là bắt buộc"),
  email: yup.string().email("Email không hợp lệ").required("Email là bắt buộc"),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, "Số điện thoại không hợp lệ")
    .required("Số điện thoại là bắt buộc"),
  type: yup.string().required("Vui lòng chọn loại góp ý"),
  mapSignId: yup
    .string()
    .test(
      "conditional-required",
      "Vui lòng nhập ID biển báo",
      function (value) {
        return (
          this.parent.type !== "map_sign_feedback" ||
          (this.parent.type === "map_sign_feedback" && !!value)
        );
      }
    ),
  content: yup.string().required("Nội dung góp ý là bắt buộc"),
});

// Styles cho modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  overflowY: "auto",
  borderRadius: "16px",
};

// Style mới cho phần tiêu đề
const titleStyle = {
  backgroundColor: "#1976d2",
  color: "white",
  padding: "16px",
  textAlign: "center",
  borderTopLeftRadius: "16px",
  borderTopRightRadius: "16px",
};

// Style cho phần nội dung
const contentStyle = {
  padding: "24px",
};

const FeedbackModal = ({ open, onClose, mapSignId = null }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      type: "",
      mapSignId: mapSignId || "",
      content: "",
    },
  });

  const selectedType = watch("type");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getFromIndexedDB("userData");
        if (userData) {
          setIsLoggedIn(true);
          setValue("name", userData.full_name);
          setValue("email", userData.email);
          setValue("phone", userData.phone_number || "");
          setUserId(userData.id);
        } else {
          setIsLoggedIn(false);
          setValue("name", "");
          setValue("email", "");
          setValue("phone", "");
          setUserId(null);
        }
      } catch (error) {
        console.error("Lỗi khi lấy thông tin người dùng:", error);
        toast.error(
          "Không thể tải thông tin người dùng. Vui lòng thử lại sau."
        );
      }
    };

    if (open) {
      fetchUserData();
    }
  }, [open, setValue]);

  const validateMapSignId = async (id) => {
    try {
      const response = await getMapSignById(id);
      return response && response.id ? true : false;
    } catch (error) {
      return false;
    }
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      if (data.type === "map_sign_feedback") {
        const isValidMapSign = await validateMapSignId(data.mapSignId);
        if (!isValidMapSign) {
          setError("mapSignId", {
            type: "manual",
            message: "ID biển báo không tồn tại",
          });
          setIsSubmitting(false);
          return;
        }
      }

      const feedbackData = {
        user_id: userId,
        user_email: data.email,
        user_phone_number: data.phone,
        type: data.type,
        map_sign_id: data.type === "map_sign_feedback" ? data.mapSignId : null,
        detail: data.content,
      };

      const response = await createUserFeedback(feedbackData);

      if (response && response.id) {
        toast.success("Góp ý đã được gửi thành công!");
        handleClose();
      } else {
        toast.error("Có lỗi xảy ra khi gửi góp ý. Vui lòng thử lại.");
      }
    } catch (error) {
      console.error("Lỗi khi gửi góp ý:", error);
      let errorMessage = "Có lỗi xảy ra khi gửi góp ý. Vui lòng thử lại sau.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    reset({
      name: "",
      email: "",
      phone: "",
      type: "",
      mapSignId: "",
      content: "",
    });
    onClose();
  };

  return ReactDOM.createPortal(
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* Phần tiêu đề mới */}
        <Box sx={titleStyle}>
          <Typography variant="h6" component="h2">
            ĐÓNG GÓP Ý KIẾN
          </Typography>
        </Box>

        {/* Divider để chia tiêu đề và nội dung */}
        <Divider />

        {/* Phần nội dung */}
        <Box sx={contentStyle}>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Họ và tên"
                      variant="outlined"
                      disabled={isLoggedIn}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Số điện thoại"
                      variant="outlined"
                      disabled={isLoggedIn}
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Email"
                      variant="outlined"
                      disabled={isLoggedIn}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" error={!!errors.type}>
                  <InputLabel id="feedback-type-label">Vấn đề góp ý</InputLabel>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="feedback-type-label"
                        label="Vấn đề góp ý"
                      >
                        <MenuItem value="map_sign_feedback">
                          Góp ý về biển báo
                        </MenuItem>
                        <MenuItem value="general_feedback">
                          Góp ý chung
                        </MenuItem>
                        <MenuItem value="technical_feedback">
                          Góp ý kỹ thuật
                        </MenuItem>
                      </Select>
                    )}
                  />
                  {errors.type && (
                    <Typography variant="caption" color="error">
                      {errors.type.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              {selectedType === "map_sign_feedback" && (
                <Grid item xs={6}>
                  <Controller
                    name="mapSignId"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="ID biển báo"
                        variant="outlined"
                        error={!!errors.mapSignId}
                        helperText={errors.mapSignId?.message}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  name="content"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Nội dung góp ý"
                      variant="outlined"
                      multiline
                      rows={4}
                      error={!!errors.content}
                      helperText={errors.content?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
                >
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    disabled={isSubmitting}
                  >
                    Hủy
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? <CircularProgress size={20} /> : null
                    }
                  >
                    {isSubmitting ? "Đang gửi..." : "Gửi góp ý"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>,
    document.body
  );
};

export default FeedbackModal;

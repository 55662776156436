import React, { useState, useEffect } from "react";
import { CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import Avatar from "../../common/Avatar";
import NotificationComponent from "../../notification/NotificationComponent";
import { getFromIndexedDB } from "../../../utils/indexedDB";
import axios from "axios";
import { API_URL } from "../../../config";

const UserHeader = ({ toggleSidebar }) => {
  const [notifications, setNotifications] = useState([]);
  const [isNotificationHovered, setIsNotificationHovered] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = await getFromIndexedDB("token");
        const response = await axios.get(`${API_URL}/notifications`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setNotifications(response.data);
      } catch (error) {
        console.error("Lỗi khi lấy thông báo:", error);
      }
    };

    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const markNotificationAsRead = async (notificationId) => {
    try {
      const token = await getFromIndexedDB("token");
      await axios.patch(
        `${API_URL}/notifications/${notificationId}/read`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, is_read: true }
            : notification
        )
      );
    } catch (error) {
      console.error("Lỗi khi đánh dấu thông báo đã đọc:", error);
    }
  };

  const handleNotificationMouseEnter = () => {
    setIsNotificationHovered(true);
  };

  const handleNotificationMouseLeave = () => {
    setIsNotificationHovered(false);
  };

  return (
    <header className="w-full flex justify-between items-center px-4 py-2 bg-white border-b border-gray-200">
      <CButton
        onClick={toggleSidebar}
        className="bg-gray-100 hover:bg-gray-200 text-gray-600 p-2 rounded-md"
      >
        <CIcon icon={cilMenu} />
      </CButton>
      <div className="flex items-center">
        <NotificationComponent
          notifications={notifications}
          markNotificationAsRead={markNotificationAsRead}
          isHovered={isNotificationHovered}
          onMouseEnter={handleNotificationMouseEnter}
          onMouseLeave={handleNotificationMouseLeave}
          className="mr-4"
          position={{ top: "100%", right: "0" }}
        />
        <Avatar />
      </div>
    </header>
  );
};

export default UserHeader;

const DistanceSignComponent = ({
  width = 150,
  height = 60,
  content,
  backgroundColor = "white",
  borderColor = "#000000",
  contentColor = "#000000",
  contentStrokeColor,
  contentStrokeWidth,
  isIcon = false,
}) => {
  const borderWidth = Math.max(2, Math.floor(Math.min(width, height) * 0.01));
  const fontSize = Math.floor(height * 0.4);

  // Tính toán kích thước và vị trí của vùng nội dung
  const contentWidth = width * 0.8;
  const contentHeight = height * 0.8;
  const contentX = (width - contentWidth) / 2;
  const contentY = (height - contentHeight) / 2;

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        position: "relative",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
      >
        {/* Hình chữ nhật nền */}
        <rect
          x={borderWidth / 2}
          y={borderWidth / 2}
          width={width - borderWidth}
          height={height - borderWidth}
          fill={backgroundColor}
          stroke={borderColor}
          strokeWidth={borderWidth}
        />

        {/* Nội dung (text hoặc icon) */}
        <foreignObject
          x={contentX}
          y={contentY}
          width={contentWidth}
          height={contentHeight}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: contentColor,
              fontSize: `${fontSize}px`,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {isIcon ? (
              content
            ) : (
              <span
                style={{
                  WebkitTextStroke: contentStrokeWidth
                    ? `${contentStrokeWidth}px ${contentStrokeColor}`
                    : "none",
                }}
              >
                {content}
              </span>
            )}
          </div>
        </foreignObject>
      </svg>
    </div>
  );
};

export default DistanceSignComponent;

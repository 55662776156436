import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import Avatar from "@mui/material/Avatar";
import { getUserById } from "../../services/api";

const ChatContent = ({ content, currentUserId }) => {
  const [userAvatars, setUserAvatars] = useState({});

  useEffect(() => {
    const fetchAvatars = async () => {
      try {
        const parsedContent = JSON.parse(content);
        const userIds = Array.isArray(parsedContent)
          ? [...new Set(parsedContent.map((item) => item.user_id))]
          : [parsedContent.user_id];

        const avatarPromises = userIds.map(async (userId) => {
          try {
            const userData = await getUserById(userId);
            return [
              userId,
              userData.avatar
                ? `/media/avatars/${userData.avatar}`
                : "/media/avatars/default-avatar.png",
            ];
          } catch (error) {
            console.error(`Lỗi khi lấy avatar cho user ${userId}:`, error);
            return [userId, "/media/avatars/default-avatar.png"];
          }
        });

        const avatarResults = await Promise.all(avatarPromises);
        setUserAvatars(Object.fromEntries(avatarResults));
      } catch (error) {
        console.error("Lỗi khi phân tích nội dung hoặc lấy avatars:", error);
      }
    };

    fetchAvatars();
  }, [content]);

  const renderMessage = (item, isCurrentUser) => {
    const avatar =
      userAvatars[item.user_id] || "/media/avatars/default_avatar.png";

    return (
      <div
        key={item.update_time}
        className={`flex ${
          isCurrentUser ? "justify-end" : "justify-start"
        } mb-3`}
      >
        {!isCurrentUser && (
          <Avatar
            src={avatar}
            sx={{ width: 34, height: 34, marginRight: 0.5 }}
          />
        )}
        <div style={{ maxWidth: "70%" }}>
          <div
            className={`py-2 px-3 rounded ${
              isCurrentUser ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
            style={{ fontSize: "0.9rem", lineHeight: "1.2" }}
          >
            {item.content}
          </div>
          <div className={` ${isCurrentUser ? "text-right" : "text-left"}`}>
            <small className="text-gray-500" style={{ fontSize: "0.75rem" }}>
              {format(new Date(item.update_time), "dd/MM/yyyy HH:mm")}
            </small>
          </div>
        </div>
        {isCurrentUser && (
          <Avatar
            src={avatar}
            sx={{ width: 34, height: 34, marginLeft: 0.5 }}
          />
        )}
      </div>
    );
  };

  const renderContent = () => {
    try {
      const parsedContent = JSON.parse(content);
      if (Array.isArray(parsedContent)) {
        return parsedContent.map((item) =>
          renderMessage(item, item.user_id === currentUserId)
        );
      } else {
        return renderMessage(
          parsedContent,
          parsedContent.user_id === currentUserId
        );
      }
    } catch (error) {
      console.error("Lỗi khi phân tích nội dung:", error);
      return <p>{content}</p>;
    }
  };

  return <>{renderContent()}</>;
};

export default ChatContent;

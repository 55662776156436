// Component hiển thị biển báo giờ và ngày
const HoursDaysSignComponent = ({
  width = 140,
  height = 50,
  backgroundColor = "white",
  borderColor = "#000000",
  contentColor = "#000000",
  contentStrokeColor,
  contentStrokeWidth,
  hour_range_count,
  day_range_count,
  hour_ranges,
  day_ranges,
}) => {
  // Tính toán độ rộng viền dựa trên kích thước component
  const borderWidth = Math.max(2, Math.floor(Math.min(width, height) * 0.01));
  // Tính toán kích thước font chữ
  const fontSize = Math.floor(height * 0.35); // Tăng kích thước font

  // Hàm render nội dung của biển báo
  const renderContent = () => {
    if (hour_range_count > 0 && Array.isArray(hour_ranges)) {
      return hour_ranges.map((range, index) => (
        <div key={index}>
          {range[0]} - {range[1]}
        </div>
      ));
    } else if (day_range_count > 0 && Array.isArray(day_ranges)) {
      return day_ranges.map((range, index) => (
        <div key={index}>
          {range[0]} - {range[1]}
        </div>
      ));
    }
    return null;
  };

  // Tính toán chiều cao dựa trên số lượng khoảng thời gian
  const contentHeight = Math.max(hour_range_count, day_range_count) * 15; // Tăng chiều cao mỗi dòng
  const adjustedHeight = Math.max(height, contentHeight + 5); // Giảm padding xuống 2px

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${adjustedHeight}px`,
        position: "relative",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${width} ${adjustedHeight}`}
        width={width}
        height={adjustedHeight}
      >
        {/* Hình chữ nhật nền */}
        <rect
          x={borderWidth / 2}
          y={borderWidth / 2}
          width={width - borderWidth}
          height={adjustedHeight - borderWidth}
          fill={backgroundColor}
          stroke={borderColor}
          strokeWidth={borderWidth}
        />

        {/* Nội dung */}
        <foreignObject
          x={borderWidth}
          y={borderWidth}
          width={width - 2 * borderWidth}
          height={adjustedHeight - 2 * borderWidth}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: contentColor,
              fontSize: `${fontSize}px`,
              fontWeight: "bold",
              textAlign: "center",
              WebkitTextStroke: contentStrokeWidth
                ? `${contentStrokeWidth}px ${contentStrokeColor}`
                : "none",
            }}
          >
            {renderContent()}
          </div>
        </foreignObject>
      </svg>
    </div>
  );
};

export default HoursDaysSignComponent;

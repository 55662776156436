import React, { useState, useEffect, useMemo } from "react";
import { useAuth } from "../../hooks/useAuth";
import { usePermission } from "../../hooks/usePermission";
import { useNavigate } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import {
  cilUser,
  cilSettings,
  cilShieldAlt,
  cilAccountLogout,
  cilCaretBottom,
  cilBell,
} from "@coreui/icons";
import { motion, AnimatePresence } from "framer-motion";
import { SERVER_URL } from "../../config";
import { getFromIndexedDB, removeFromIndexedDB } from "../../utils/indexedDB";
import NotificationList from "../notification/NotificationList";
import useUserData from "../../hooks/useUserData";
import toast, { Toaster } from "react-hot-toast";
import {
  getNotifications,
  markNotificationAsRead as markNotificationAsReadAPI,
} from "../../services/api";

// Tạo một hook tùy chỉnh để xử lý CSS animation
const useRingingAnimation = (unreadNotificationsCount) => {
  return `
    @keyframes ringing {
      0% { transform: rotate(0deg); }
      5% { transform: rotate(15deg); }
      10% { transform: rotate(-15deg); }
      15% { transform: rotate(15deg); }
      20% { transform: rotate(-15deg); }
      25% { transform: rotate(0deg); }
      100% { transform: rotate(0deg); }
    }
    .bell-icon-wrapper {
      animation: ${
        unreadNotificationsCount > 0 ? "ringing 2s infinite" : "none"
      };
      transform-origin: 50% 0;
    }
  `;
};

const HomeAvatar = ({ className }) => {
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const { permissions } = usePermission();
  const [userId, setUserId] = useState(null);
  const { userData, loading } = useUserData(userId);
  const [notifications, setNotifications] = useState([]);
  const [isMenuHovered, setIsMenuHovered] = useState(false);
  const [isNotificationHovered, setIsNotificationHovered] = useState(false);

  const ringingAnimationStyle = useRingingAnimation(
    notifications.filter((notification) => !notification.is_read).length
  );

  useEffect(() => {
    const fetchUserId = async () => {
      const id = await getFromIndexedDB("userId");
      setUserId(id);
    };

    if (isLoggedIn) {
      fetchUserId();
    }
  }, [isLoggedIn]);

  const fetchNotifications = async () => {
    if (isLoggedIn) {
      try {
        const response = await getNotifications();
        setNotifications(response);
      } catch (error) {
        console.error("Lỗi khi lấy thông báo:", error);
        await handleLogout();
      }
    }
  };

  useEffect(() => {
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 60000); // Cập nhật mỗi phút

    return () => clearInterval(intervalId);
  }, [isLoggedIn]);

  const avatarUrl = useMemo(() => {
    if (isLoggedIn && userData && userData.avatar) {
      return userData.avatar.startsWith("http")
        ? userData.avatar
        : `${SERVER_URL}/media/avatars/${userData.avatar}`;
    }
    return `${SERVER_URL}/media/avatars/default_avatar.png`;
  }, [isLoggedIn, userData]);

  const handleMenuMouseEnter = () => setIsMenuHovered(true);
  const handleMenuMouseLeave = () => setIsMenuHovered(false);

  const handleNotificationMouseEnter = () => setIsNotificationHovered(true);
  const handleNotificationMouseLeave = () => setIsNotificationHovered(false);

  const getHeight = () => {
    const baseHeight = 5.3;
    const buttonHeight = 2;
    const buttonCount = permissions.canAccessAdminPanel ? 4 : 3;
    return isMenuHovered && isLoggedIn
      ? `${baseHeight + buttonCount * buttonHeight}rem`
      : `${baseHeight}rem`;
  };

  const handleLogout = async () => {
    try {
      await removeFromIndexedDB("token");
      await removeFromIndexedDB("userId");
      await removeFromIndexedDB("user");
      await removeFromIndexedDB(`userData`);
      await removeFromIndexedDB("permissions");
      logout();
      navigate("/");
    } catch (error) {
      console.error("Lỗi khi đăng xuất:", error);
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleAvatarClick = () => {
    navigate(isLoggedIn ? "/user" : "/login");
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      await markNotificationAsReadAPI(notificationId);
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, is_read: true }
            : notification
        )
      );
    } catch (error) {
      console.error("Lỗi khi đánh dấu thông báo đã đọc:", error);
    }
  };

  const handleNotificationsUpdated = (updatedNotifications) => {
    setNotifications(updatedNotifications);
  };

  const unreadNotificationsCount = useMemo(() => {
    return notifications.filter((notification) => !notification.is_read).length;
  }, [notifications]);

  if (loading) {
    return null; // Hoặc hiển thị một loading spinner
  }

  return (
    <>
      {/* 
        Vị trí của HomeAvatar đã được điều chỉnh để nằm ở trên cùng, góc phải.
        - top-0: đặt component ở phía trên cùng
        - right-0: đặt component ở phía phải
        - p-4: thêm padding để tránh sát mép
        - z-50: đảm bảo hiển thị trên các phần tử khác
      */}
      <div className={`fixed top-1 right-0 p-2 z-50 ${className}`}>
        <div className="flex flex-col items-center">
          <div className="relative">
            {isLoggedIn && (
              <>
                <motion.div
                  className="absolute top-[55%] left-1/2 transform -translate-x-1/2 w-14 bg-white bg-opacity-70 rounded-lg shadow-md -z-10 overflow-hidden px-1"
                  initial={{ height: "5rem" }}
                  animate={{ height: getHeight() }}
                  transition={{ duration: 0.3 }}
                >
                  <AnimatePresence>
                    {!isMenuHovered && (
                      <motion.div
                        className="absolute top-[70%] left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        initial={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <div className="flex flex-col items-center mt-2">
                          <div
                            className="bell-icon-wrapper relative cursor-pointer"
                            onMouseEnter={handleNotificationMouseEnter}
                            onMouseLeave={handleNotificationMouseLeave}
                          >
                            <CIcon icon={cilBell} size="lg" />
                            {unreadNotificationsCount > 0 && (
                              <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                                {unreadNotificationsCount}
                              </span>
                            )}
                          </div>
                          <CIcon
                            icon={cilCaretBottom}
                            size="sm"
                            className="mt-1 mb-2 cursor-pointer"
                            onMouseEnter={handleMenuMouseEnter}
                          />
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <AnimatePresence>
                    {isMenuHovered && (
                      <motion.div
                        className="absolute top-5 left-0 w-full h-full flex flex-col justify-start items-center pt-3"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        onMouseLeave={handleMenuMouseLeave}
                      >
                        <button
                          onClick={() => handleNavigate("/user")}
                          className="block w-full text-center py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                        >
                          <CIcon icon={cilUser} className="mx-auto" size="lg" />
                        </button>
                        <button
                          onClick={() => handleNavigate("/user/settings")}
                          className="block w-full text-center py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                        >
                          <CIcon
                            icon={cilSettings}
                            className="mx-auto"
                            size="lg"
                          />
                        </button>
                        {permissions.canAccessAdminPanel && (
                          <button
                            onClick={() => handleNavigate("/admin")}
                            className="block w-full text-center py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                          >
                            <CIcon
                              icon={cilShieldAlt}
                              className="mx-auto"
                              size="lg"
                            />
                          </button>
                        )}
                        <button
                          onClick={handleLogout}
                          className="block w-full text-center py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                        >
                          <CIcon
                            icon={cilAccountLogout}
                            className="mx-auto"
                            size="lg"
                          />
                        </button>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              </>
            )}

            <motion.div
              className="relative z-10 w-16 h-16 rounded-full bg-gray-300 overflow-visible cursor-pointer"
              whileHover={{
                scale: 1.05,
                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
              }}
              whileTap={{
                scale: 0.95,
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.3)",
              }}
              transition={{
                type: "spring",
                stiffness: 300,
                damping: 15,
                boxShadow: { duration: 0.1 },
              }}
              onClick={handleAvatarClick}
            >
              <img
                src={avatarUrl}
                alt="Avatar"
                className="w-full h-full object-cover rounded-full"
              />
            </motion.div>
          </div>
        </div>
      </div>
      <NotificationList
        notifications={notifications}
        markNotificationAsRead={markNotificationAsRead}
        isVisible={isNotificationHovered}
        onMouseEnter={handleNotificationMouseEnter}
        onMouseLeave={handleNotificationMouseLeave}
        position={{ top: "60px", right: "80px" }}
        onNotificationsUpdated={handleNotificationsUpdated}
      />
      <Toaster />
      <style>{ringingAnimationStyle}</style>
    </>
  );
};

export default HomeAvatar;

import CryptoJS from "crypto-js";

// Cấu hình cơ bản cho IndexedDB
const DB_NAME = "tsmDB";
const STORE_NAME = "tsmStore";
const DB_VERSION = 2;

// Lấy khóa mã hóa từ biến môi trường
const ENCRYPTION_KEY = process.env.REACT_APP_USER_ENCRYPTION_KEY;

// Hàm mã hóa dữ liệu
const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
};

// Hàm giải mã dữ liệu
const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// Hàm khởi tạo và mở kết nối đến IndexedDB
const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => reject("Lỗi khi mở IndexedDB");

    request.onsuccess = (event) => resolve(event.target.result);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      // Kiểm tra xem object store đã tồn tại chưa
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        // Nếu chưa tồn tại, tạo mới
        const store = db.createObjectStore(STORE_NAME, { keyPath: "key" });
        // Thêm index cho expirationDate
        store.createIndex("expirationDate", "expirationDate");
      } else {
        // Nếu đã tồn tại, lấy reference đến object store
        const transaction = event.target.transaction;
        const store = transaction.objectStore(STORE_NAME);

        // Kiểm tra xem index đã tồn tại chưa
        if (!store.indexNames.contains("expirationDate")) {
          // Nếu chưa tồn tại, thêm mới
          store.createIndex("expirationDate", "expirationDate");
        }
      }
    };
  });
};

// Hàm lưu dữ liệu vào IndexedDB với thời gian hết hạn
export const saveToIndexedDB = async (key, value, expirationInMinutes = 60) => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], "readwrite");
    const store = transaction.objectStore(STORE_NAME);

    // Tính toán thời gian hết hạn
    const expirationDate = new Date(Date.now() + expirationInMinutes * 60000);

    const encryptedValue = encryptData({
      data: value,
      expirationDate: expirationDate.getTime(),
    });

    const request = store.put({
      key,
      value: encryptedValue,
      expirationDate: expirationDate.getTime(),
    });

    request.onerror = () => reject("Lỗi khi lưu dữ liệu");
    request.onsuccess = () => resolve();
  });
};

// Hàm lấy dữ liệu từ IndexedDB và kiểm tra hết hạn
export const getFromIndexedDB = async (key) => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], "readonly");
    const store = transaction.objectStore(STORE_NAME);
    const request = store.get(key);

    request.onerror = () => reject("Lỗi khi lấy dữ liệu");
    request.onsuccess = (event) => {
      if (event.target.result) {
        const decryptedData = decryptData(event.target.result.value);
        if (decryptedData.expirationDate > Date.now()) {
          resolve(decryptedData.data);
        } else {
          // Nếu dữ liệu đã hết hạn, xóa nó và trả về null
          removeFromIndexedDB(key);
          resolve(null);
        }
      } else {
        resolve(null);
      }
    };
  });
};

// Hàm xóa dữ liệu từ IndexedDB
export const removeFromIndexedDB = async (key) => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], "readwrite");
    const store = transaction.objectStore(STORE_NAME);
    const request = store.delete(key);

    request.onerror = () => reject("Lỗi khi xóa dữ liệu");
    request.onsuccess = () => resolve();
  });
};

// Hàm xóa tất cả dữ liệu đã hết hạn
export const clearExpiredData = async () => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], "readwrite");
    const store = transaction.objectStore(STORE_NAME);
    const index = store.index("expirationDate");
    const now = Date.now();

    const request = index.openCursor(IDBKeyRange.upperBound(now));

    request.onerror = () => reject("Lỗi khi xóa dữ liệu hết hạn");
    request.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        store.delete(cursor.primaryKey);
        cursor.continue();
      } else {
        resolve();
      }
    };
  });
};

// Hàm mới: Xóa cache cũ nhất
export const clearOldestFromIndexedDB = async (storeName, maxEntries) => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], "readwrite");
    const store = transaction.objectStore(storeName);
    const countRequest = store.count();

    countRequest.onsuccess = () => {
      const totalEntries = countRequest.result;
      if (totalEntries <= maxEntries) {
        resolve();
        return;
      }

      const entriesToDelete = totalEntries - maxEntries;
      let deletedCount = 0;

      const cursorRequest = store.openCursor();
      cursorRequest.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor && deletedCount < entriesToDelete) {
          store.delete(cursor.primaryKey);
          deletedCount++;
          cursor.continue();
        } else {
          resolve();
        }
      };
    };

    transaction.onerror = () => reject("Lỗi khi xóa các entries cũ nhất");
  });
};

// Gọi hàm clearExpiredData mỗi khi ứng dụng khởi động
clearExpiredData();

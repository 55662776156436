import React, { useState, useEffect, useRef, useCallback } from "react";
import { useAuth } from "../../hooks/useAuth";
import { database } from "../../config/firebase";
import {
  ref,
  onValue,
  push,
  serverTimestamp,
  update,
  remove,
  query,
  limitToLast,
} from "firebase/database";
import { useNavigate } from "react-router-dom";
import { getUserIcons } from "../../services/api";
import CIcon from "@coreui/icons-react";
import {
  cilCaretTop,
  cilCaretBottom,
  cilCommentSquare,
  cilX,
  cilSmile,
} from "@coreui/icons";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { getFromIndexedDB } from "../../utils/indexedDB";
import toast, { Toaster } from "react-hot-toast";
import { Modal, Box, Typography, Button } from "@mui/material";

// Danh sách từ xấu (nên đặt trong một file riêng trong thực tế)
const BAD_WORDS = ["dm", "vl", "lồn", "đm", "mẹ", "đỉ", "chó", "điên", "vãi"]; // Thêm các từ xấu vào đây

const Chatbox = () => {
  // Khai báo các state
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [userIcons, setUserIcons] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isChatboxOpen, setIsChatboxOpen] = useState(() => {
    return localStorage.getItem("isChatboxOpen") !== "false";
  });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [replyTo, setReplyTo] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [lastMessageTime, setLastMessageTime] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const [userStatus, setUserStatus] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);

  // Sử dụng các hooks
  const { user } = useAuth();
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const hoverTimeoutRef = useRef(null);
  const navigate = useNavigate();

  // Các hằng số
  const MAX_MESSAGES = 50;
  const MAX_MESSAGES_PER_MINUTE = 5;
  const MAX_MESSAGE_LENGTH = 100; // Giới hạn số ký tự cho mỗi tin nhắn

  // Lấy tin nhắn từ Firebase
  useEffect(() => {
    const messagesRef = ref(database, "messages");
    const messagesQuery = query(messagesRef, limitToLast(MAX_MESSAGES));
    const unsubscribe = onValue(messagesQuery, (snapshot) => {
      const messagesData = snapshot.val();
      if (messagesData) {
        const messagesList = Object.entries(messagesData).map(
          ([id, message]) => ({
            id,
            ...message,
          })
        );
        setMessages(messagesList);

        if (user) {
          const unreadMessages = messagesList.filter(
            (message) => !message.readBy || !message.readBy[user.id]
          );
          setUnreadCount(unreadMessages.length);
        } else {
          setUnreadCount(0);
        }

        // Lấy avatar người dùng
        messagesList.forEach((message) => {
          if (!userIcons[message.userId]) {
            getUserIcons(message.userId)
              .then((icons) => {
                setUserIcons((prevIcons) => ({
                  ...prevIcons,
                  [message.userId]: icons.avatar,
                }));
              })
              .catch((error) =>
                console.error("Lỗi khi lấy thông tin avatar:", error)
              );
          }
        });

        // Xóa tin nhắn cũ nếu vượt quá MAX_MESSAGES
        if (messagesList.length > MAX_MESSAGES) {
          const oldestMessageId = messagesList[0].id;
          remove(ref(database, `messages/${oldestMessageId}`));
        }
      }
    });

    return () => unsubscribe();
  }, [user]);

  // Cuộn xuống cuối cùng khi có tin nhắn mới
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Lưu trạng thái mở/đóng chatbox vào localStorage
  useEffect(() => {
    localStorage.setItem("isChatboxOpen", isChatboxOpen);
  }, [isChatboxOpen]);

  // Lấy role và status của người dùng từ IndexedDB
  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userData = await getFromIndexedDB("userData");
        if (userData) {
          setUserRole(userData.role_id);
          setUserStatus(userData.status);
        }
      }
    };
    fetchUserData();
  }, [user]);

  // Xử lý emoji
  const addEmoji = (emoji) => {
    setInputMessage((prevMessage) => prevMessage + emoji.native);
  };

  const replaceEmojiShortcuts = (message) => {
    const emojiMap = {
      ":D": "😃",
      ":)": "🙂",
      ":(": "🙁",
      ";)": "😉",
      ":P": "😛",
    };

    return message.replace(
      /:\)|:\(|:D|;\)|:P/g,
      (match) => emojiMap[match] || match
    );
  };

  // Xử lý input
  const handleInputChange = (e) => {
    const newMessage = replaceEmojiShortcuts(e.target.value);
    if (newMessage.length <= MAX_MESSAGE_LENGTH) {
      setInputMessage(newMessage);
    } else {
      toast.error(`Tin nhắn không được vượt quá ${MAX_MESSAGE_LENGTH} ký tự`);
    }
  };

  // Hàm để ẩn ngẫu nhiên các ký tự trong chuỗi
  const hideRandomCharacters = (str) => {
    const minVisible = Math.max(2, Math.floor(str.length * 0.3)); // Ít nhất 30% ký tự hiển thị, tối thiểu 2 ký tự
    const charsToHide = str.length - minVisible;
    let hiddenStr = str.split("");

    for (let i = 0; i < charsToHide; i++) {
      let index;
      do {
        index = Math.floor(Math.random() * str.length);
      } while (hiddenStr[index] === "*");
      hiddenStr[index] = "*";
    }

    return hiddenStr.join("");
  };

  // Xử lý URL trong tin nhắn
  const processMessageText = (text, isAdmin) => {
    if (isAdmin) return text;

    // Xử lý URL có http/https
    text = text.replace(/(https?:\/\/\S+)/gi, (url) => {
      const domain = url.split("//")[1].split("/")[0];
      return hideRandomCharacters(domain);
    });

    // Xử lý URL không có http/https
    text = text.replace(
      /(?<!\S)((?!https?:\/\/)\S+\.\S+(?:\/\S*)?)\b/gi,
      (url) => {
        const domain = url.split("/")[0];
        return hideRandomCharacters(domain);
      }
    );

    return text;
  };

  // Bộ lọc từ xấu
  const filterBadWords = (text) => {
    let filteredText = text;
    BAD_WORDS.forEach((word) => {
      const regex = new RegExp(word, "gi");
      filteredText = filteredText.replace(regex, "*".repeat(word.length));
    });
    return filteredText;
  };

  // Gửi tin nhắn
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() && user && userStatus !== "ban") {
      if (inputMessage.length > MAX_MESSAGE_LENGTH) {
        toast.error(`Tin nhắn không được vượt quá ${MAX_MESSAGE_LENGTH} ký tự`);
        return;
      }

      const now = Date.now();
      if (now - lastMessageTime < 60000) {
        if (messageCount >= MAX_MESSAGES_PER_MINUTE) {
          toast.error("Bạn đã gửi quá nhiều tin nhắn. Vui lòng đợi một chút.");
          return;
        }
        setMessageCount(messageCount + 1);
      } else {
        setLastMessageTime(now);
        setMessageCount(1);
      }

      const processedText = processMessageText(inputMessage, userRole === 7);
      const filteredText = filterBadWords(processedText);

      const newMessage = {
        text: filteredText,
        timestamp: serverTimestamp(),
        userId: user.id,
        userName: user.full_name,
        readBy: {
          [user.id]: true,
        },
        replyTo: replyTo,
      };

      push(ref(database, "messages"), newMessage)
        .then(() => {
          setInputMessage("");
          setShowEmojiPicker(false);
          setReplyTo(null);
        })
        .catch((error) => {
          console.error("Lỗi khi gửi tin nhắn:", error);
          toast.error("Không thể gửi tin nhắn. Vui lòng thử lại sau.");
        });
    }
  };

  // Các hàm điều khiển giao diện
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleChatbox = () => {
    setIsChatboxOpen(!isChatboxOpen);
  };

  // Xử lý tin nhắn
  const markAsRead = (messageId) => {
    if (user) {
      const messageRef = ref(database, `messages/${messageId}/readBy`);
      update(messageRef, { [user.id]: true });
    }
  };

  const markAllAsRead = () => {
    if (user) {
      messages.forEach((message) => {
        if (!message.readBy || !message.readBy[user.id]) {
          markAsRead(message.id);
        }
      });
    }
  };

  // Xử lý sự kiện chuột
  const handleInputHover = () => {
    if (unreadCount > 0 && user) {
      markAllAsRead();
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
  };

  const handleMouseLeave = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setIsHovered(false);
    }, 600);
  };

  // Xử lý menu ngữ cảnh
  const handleContextMenu = useCallback((e, message) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.closest(".message-content")) {
      if (message.isRecalled) return;

      setContextMenu({
        x: e.clientX,
        y: e.clientY,
        message: message,
      });
    }
  }, []);

  const handleCloseContextMenu = useCallback(() => {
    setContextMenu(null);
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleCloseContextMenu);
    return () => {
      document.removeEventListener("click", handleCloseContextMenu);
    };
  }, [handleCloseContextMenu]);

  const handleRecall = useCallback((messageId) => {
    setSelectedMessageId(messageId);
    setModalAction("recall");
    setIsModalOpen(true);
  }, []);

  const handleDelete = useCallback((messageId) => {
    setSelectedMessageId(messageId);
    setModalAction("delete");
    setIsModalOpen(true);
  }, []);

  const handleConfirmAction = () => {
    if (modalAction === "recall") {
      const messageRef = ref(database, `messages/${selectedMessageId}`);
      update(messageRef, {
        text: "Tin nhắn đã được thu hồi",
        isRecalled: true,
      })
        .then(() => {
          toast.success("Tin nhắn đã được thu hồi");
        })
        .catch((error) => {
          console.error("Lỗi khi thu hồi tin nhắn:", error);
          toast.error("Không thể thu hồi tin nhắn. Vui lòng thử lại sau.");
        });
    } else if (modalAction === "delete") {
      const messageRef = ref(database, `messages/${selectedMessageId}`);
      remove(messageRef)
        .then(() => {
          toast.success("Tin nhắn đã được xóa");
        })
        .catch((error) => {
          console.error("Lỗi khi xóa tin nhắn:", error);
          toast.error("Không thể xóa tin nhắn. Vui lòng thử lại sau.");
        });
    }
    setIsModalOpen(false);
    handleCloseContextMenu();
  };

  const handleReply = useCallback(
    (message) => {
      setReplyTo(message);
      handleCloseContextMenu();
    },
    [handleCloseContextMenu]
  );

  // Render component
  return (
    <div onClick={handleCloseContextMenu}>
      {/* Hiển thị thông báo toast */}
      <Toaster position="top-center" reverseOrder={false} />

      {/* Container chatbox */}
      <div
        className={`fixed bottom-4 left-4 ${
          isChatboxOpen
            ? `w-96 ${isExpanded ? "h-[80vh]" : "h-60"}`
            : "w-12 h-12"
        } rounded-lg shadow-lg transition-all duration-300 z-50 hidden md:block`}
        style={{
          backgroundColor:
            isHovered || !isChatboxOpen
              ? "rgba(255, 255, 255, 1)"
              : "rgba(255, 255, 255, 0)",
          backdropFilter: "blur(3px)",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isChatboxOpen ? (
          <div
            className={`h-full flex flex-col transition-opacity duration-300 ${
              isHovered ? "opacity-100" : "opacity-60"
            }`}
          >
            {/* Header chatbox */}
            <div className="relative h-8">
              <button
                onClick={toggleExpand}
                className="absolute top-2 left-1/2 transform -translate-x-1/2 text-gray-600 hover:text-gray-800 transition-colors"
              >
                <CIcon
                  icon={isExpanded ? cilCaretBottom : cilCaretTop}
                  size="sm"
                />
              </button>
              <button
                onClick={toggleChatbox}
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 transition-colors"
              >
                <CIcon icon={cilX} size="sm" />
              </button>
            </div>

            {/* Container tin nhắn */}
            <div
              ref={chatContainerRef}
              className="flex-grow p-3 overflow-y-auto"
              style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            >
              {messages.map((message) => (
                <div
                  key={message.id}
                  className="mb-4 flex items-start"
                  onClick={() => user && markAsRead(message.id)}
                  onContextMenu={(e) => handleContextMenu(e, message)}
                >
                  {user && (!message.readBy || !message.readBy[user.id]) && (
                    <div className="w-2 h-2 bg-blue-500 rounded-full mt-2 mr-2 flex-shrink-0"></div>
                  )}
                  <div className="flex-grow">
                    <div className="flex items-center">
                      <img
                        src={
                          userIcons[message.userId] ||
                          "/media/avatars/default_avatar.png"
                        }
                        alt="Avatar"
                        className="w-8 h-8 rounded-full mr-2"
                      />
                      <span className="font-semibold">{message.userName}</span>
                    </div>
                    {message.replyTo && (
                      <div className="bg-gray-200 p-2 rounded-lg mt-1 mb-2 text-sm">
                        <span className="font-semibold">
                          {message.replyTo.userName}:{" "}
                        </span>
                        {message.replyTo.text}
                      </div>
                    )}
                    <div className="bg-gray-100 p-2 rounded-lg mt-1 message-content">
                      {/* Hiển thị tin nhắn đã thu hồi với font nhỏ và in nghiêng */}
                      {message.isRecalled ? (
                        <span className="text-sm italic text-gray-500">
                          Tin nhắn đã được thu hồi
                        </span>
                      ) : (
                        <span>{message.text}</span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>

            {/* Xem trước tin nhắn trả lời */}
            {replyTo && (
              <div className="bg-gray-200 p-2 m-2 rounded-lg flex justify-between items-center">
                <span className="text-sm">
                  <span className="font-semibold">
                    Trả lời {replyTo.userName}:{" "}
                  </span>
                  {replyTo.text}
                </span>
                <button
                  onClick={() => setReplyTo(null)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <CIcon icon={cilX} size="sm" />
                </button>
              </div>
            )}

            {/* Form nhập tin nhắn */}
            <form onSubmit={handleSubmit} className="p-2 relative">
              {user ? (
                <div className="relative">
                  <textarea
                    value={inputMessage}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        if (inputMessage.trim()) {
                          handleSubmit(e);
                        }
                      }
                    }}
                    onMouseEnter={handleInputHover}
                    placeholder={
                      userStatus === "ban"
                        ? "Bạn đã bị cấm chat"
                        : "Nhập tin nhắn"
                    }
                    className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 pr-10 resize-none scrollbar-hide ${
                      userStatus === "ban"
                        ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                        : ""
                    }`}
                    disabled={userStatus === "ban"}
                    maxLength={MAX_MESSAGE_LENGTH}
                    rows="1"
                    style={{
                      minHeight: "40px",
                      maxHeight: "80px",
                      overflowY: "auto",
                      scrollbarWidth: "none", // Firefox
                      msOverflowStyle: "none", // IE 10+
                    }}
                  />
                  {/* Ẩn thanh cuộn cho Webkit browsers */}
                  <style>
                    {`
                      .scrollbar-hide::-webkit-scrollbar {
                        display: none;
                      }
                    `}
                  </style>
                  <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                    <button
                      type="button"
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                      className={`text-gray-500 hover:text-gray-700 ${
                        userStatus === "ban"
                          ? "cursor-not-allowed opacity-50"
                          : ""
                      }`}
                      disabled={userStatus === "ban"}
                    >
                      <CIcon icon={cilSmile} size="lg" />
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => navigate("/login")}
                  className="w-full px-3 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Đăng nhập để chat
                </button>
              )}
              {showEmojiPicker && userStatus !== "ban" && (
                <div className="absolute bottom-full right-0 mb-2">
                  <Picker data={data} onEmojiSelect={addEmoji} theme="light" />
                </div>
              )}
            </form>
          </div>
        ) : (
          // Nút mở chatbox khi đang đóng
          <button
            onClick={toggleChatbox}
            className="w-full h-full flex items-center justify-center text-gray-600 hover:text-gray-800 transition-colors"
          >
            <CIcon icon={cilCommentSquare} size="lg" />
            {user && unreadCount > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full px-2 py-1 text-xs">
                {unreadCount}
              </span>
            )}
          </button>
        )}
      </div>

      {/* Menu ngữ cảnh */}
      {contextMenu && (
        <div
          className="fixed bg-white rounded-lg shadow-lg py-2 z-50"
          style={{ top: contextMenu.y, left: contextMenu.x }}
          onClick={(e) => e.stopPropagation()}
        >
          {user && (
            <>
              {/* Hiển thị tùy chọn xóa cho role_id 7 */}
              {userRole === 7 && (
                <button
                  className="block w-full text-left px-3 py-2 hover:bg-gray-100"
                  onClick={() => handleDelete(contextMenu.message.id)}
                >
                  Xóa
                </button>
              )}
              {/* Hiển thị tùy chọn thu hồi cho tin nhắn của chính mình */}
              {contextMenu.message.userId === user.id && (
                <button
                  className="block w-full text-left px-3 py-2 hover:bg-gray-100"
                  onClick={() => handleRecall(contextMenu.message.id)}
                >
                  Thu hồi
                </button>
              )}
              {/* Hiển thị tùy chọn trả lời cho tin nhắn của người khác */}
              {contextMenu.message.userId !== user.id && (
                <button
                  className="block w-full text-left px-3 py-2 hover:bg-gray-100"
                  onClick={() => handleReply(contextMenu.message)}
                >
                  Trả lời
                </button>
              )}
            </>
          )}
        </div>
      )}

      {/* Modal xác nhận sử dụng MUI */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            {modalAction === "recall" ? "Thu hồi tin nhắn" : "Xóa tin nhắn"}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            Bạn có chắc chắn muốn {modalAction === "recall" ? "thu hồi" : "xóa"}{" "}
            tin nhắn này?
          </Typography>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setIsModalOpen(false)} sx={{ mr: 2 }}>
              Hủy
            </Button>
            <Button
              onClick={handleConfirmAction}
              variant="contained"
              color="error"
            >
              {modalAction === "recall" ? "Thu hồi" : "Xóa"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Chatbox;

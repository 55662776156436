import { useCallback, useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import api from "../services/api";
import { getFromIndexedDB, saveToIndexedDB } from "../utils/indexedDB";
import { debounce } from "lodash";

// Hook tùy chỉnh để quản lý quyền hạn người dùng
export const usePermission = () => {
  const { user } = useAuth();
  const [permissions, setPermissions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Hàm fetch quyền hạn từ API hoặc cache
  const fetchPermissions = useCallback(async () => {
    if (!user?.role_id) {
      setPermissions(null);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    try {
      // Kiểm tra dữ liệu trong IndexedDB
      const cachedPermissions = await getFromIndexedDB(`permissions`);

      if (cachedPermissions) {
        setPermissions(cachedPermissions);
        setIsLoading(false);
      } else {
        // Nếu không có dữ liệu trong cache, gọi API
        const response = await api.get(`/permissions/${user.role_id}`);
        const newPermissions = response.data;

        // Lưu dữ liệu mới vào IndexedDB
        await saveToIndexedDB(`permissions`, newPermissions);

        setPermissions(newPermissions);
      }
    } catch (err) {
      console.error("Lỗi khi lấy quyền:", err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [user?.role_id]);

  // Tạo phiên bản debounce của fetchPermissions
  const debouncedFetchPermissions = useCallback((callback) => {
    const debouncedFn = debounce(callback, 300);
    debouncedFn();
    return debouncedFn.cancel;
  }, []);

  // Gọi fetchPermissions khi user.role_id thay đổi
  useEffect(() => {
    const cancelDebounce = debouncedFetchPermissions(fetchPermissions);
    // Hủy debounce khi component unmount
    return cancelDebounce;
  }, [fetchPermissions, debouncedFetchPermissions]);

  // Xử lý các quyền cụ thể
  const processedPermissions = {
    canAccessAdminPanel: Boolean(permissions?.access_admin_page),
    canAddSign: Boolean(permissions?.add_sign),
    canDeleteOwnSign: Boolean(permissions?.delete_own_sign),
    canEditSign: Boolean(permissions?.edit_sign),
    canApproveSign: Boolean(permissions?.approve_sign),
    canAddSignWithoutApproval: Boolean(permissions?.add_sign_without_approval),
    canManageMapSigns: Boolean(permissions?.manage_map_signs),
    canManageUsers: Boolean(permissions?.manage_users),
  };

  return {
    permissions: processedPermissions,
    isLoading,
    isError: error,
    fetchPermissions: () => debouncedFetchPermissions(fetchPermissions),
  };
};

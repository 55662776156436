import React, { useState, useEffect, useRef } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CSpinner,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CTable,
  CInputGroup,
  CFormInput,
} from "@coreui/react";
import {
  getUserFeedbacks,
  getUserFeedbackById,
  replyUserFeedback,
  closeUserFeedback,
  deleteUserFeedback,
} from "../../../services/api";
import toast from "react-hot-toast";
import { cilSend } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { getFromIndexedDB } from "../../../utils/indexedDB";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import ChatContent from "../../common/ChatContent";

const UserFeedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [replyContent, setReplyContent] = useState("");
  const [currentUserId, setCurrentUserId] = useState(null);

  // State cho loading buttons
  const [loadingView, setLoadingView] = useState({});
  const [loadingClose, setLoadingClose] = useState({});
  const [loadingDelete, setLoadingDelete] = useState({});
  const [loadingReply, setLoadingReply] = useState(false);

  // Ref cho PerfectScrollbar
  const scrollbarRef = useRef(null);

  // Hàm để tải danh sách góp ý của người dùng hiện tại
  const fetchUserFeedbacks = async () => {
    setIsLoading(true);
    try {
      const userId = await getFromIndexedDB("userId");
      setCurrentUserId(userId);
      const response = await getUserFeedbacks();
      const userFeedbacks = response.filter(
        (feedback) => feedback.user_id === userId
      );
      setFeedbacks(userFeedbacks);
    } catch (error) {
      console.error("Lỗi khi tải danh sách góp ý:", error);
      toast.error("Không thể tải danh sách góp ý");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUserFeedbacks();
  }, []);

  useEffect(() => {
    // Cập nhật PerfectScrollbar khi nội dung thay đổi
    if (scrollbarRef.current) {
      scrollbarRef.current.updateScroll();
    }
  }, [selectedFeedback]);

  // Hàm xử lý khi nhấn nút Xem chi tiết
  const handleViewDetail = async (id) => {
    setLoadingView((prev) => ({ ...prev, [id]: true }));
    try {
      const feedback = await getUserFeedbackById(id);
      setSelectedFeedback(feedback);
      setShowDetailModal(true);
    } catch (error) {
      console.error("Lỗi khi tải chi tiết góp ý:", error);
      toast.error("Không thể tải chi tiết góp ý");
    } finally {
      setLoadingView((prev) => ({ ...prev, [id]: false }));
    }
  };

  // Hàm xử lý khi gửi trả lời
  const handleSubmitReply = async () => {
    if (!replyContent.trim()) {
      toast.error("Vui lòng nhập nội dung trả lời");
      return;
    }
    setLoadingReply(true);
    try {
      await replyUserFeedback(selectedFeedback.id, replyContent);
      toast.success("Đã gửi trả lời thành công");
      setReplyContent("");
      // Cập nhật lại chi tiết góp ý sau khi trả lời
      const updatedFeedback = await getUserFeedbackById(selectedFeedback.id);
      setSelectedFeedback(updatedFeedback);
      fetchUserFeedbacks();
      // Cuộn xuống cuối sau khi gửi trả lời
      if (scrollbarRef.current) {
        const scrollableElement = scrollbarRef.current._ps.element;
        scrollableElement.scrollTop = scrollableElement.scrollHeight;
      }
    } catch (error) {
      console.error("Lỗi khi gửi trả lời:", error);
      toast.error("Không thể gửi trả lời");
    } finally {
      setLoadingReply(false);
    }
  };

  // Hàm xử lý khi nhấn nút Đóng góp ý
  const handleClose = async (id) => {
    if (window.confirm("Bạn có chắc chắn muốn đóng góp ý này không?")) {
      setLoadingClose((prev) => ({ ...prev, [id]: true }));
      try {
        await closeUserFeedback(id);
        toast.success("Đã đóng góp ý thành công");
        fetchUserFeedbacks();
      } catch (error) {
        console.error("Lỗi khi đóng góp ý:", error);
        toast.error("Không thể đóng góp ý");
      } finally {
        setLoadingClose((prev) => ({ ...prev, [id]: false }));
      }
    }
  };

  // Hàm xử lý khi nhấn nút Xóa
  const handleDelete = async (id) => {
    if (window.confirm("Bạn có chắc chắn muốn xóa góp ý này không?")) {
      setLoadingDelete((prev) => ({ ...prev, [id]: true }));
      try {
        await deleteUserFeedback(id);
        toast.success("Đã xóa góp ý thành công");
        fetchUserFeedbacks();
      } catch (error) {
        console.error("Lỗi khi xóa góp ý:", error);
        toast.error("Không thể xóa góp ý");
      } finally {
        setLoadingDelete((prev) => ({ ...prev, [id]: false }));
      }
    }
  };

  // Hàm tạo style chung cho badge
  const createBadgeStyle = (backgroundColor, textColor = "white") => ({
    backgroundColor,
    color: textColor,
    padding: "5px 10px",
    borderRadius: "4px",
    fontWeight: "bold",
    fontSize: "0.875rem",
    display: "inline-block",
  });

  // Hàm để render loại góp ý
  const renderType = (type) => {
    let text = type;
    let style = createBadgeStyle("#696969"); // Màu nền mặc định

    switch (type) {
      case "map_sign_feedback":
        text = "Góp ý biển báo";
        style = createBadgeStyle("#4CAF50"); // Màu xanh lá
        break;
      case "general_feedback":
        text = "Góp ý chung";
        style = createBadgeStyle("#2196F3"); // Màu xanh dương
        break;
      case "technical_feedback":
        text = "Góp ý kỹ thuật";
        style = createBadgeStyle("#FFC107", "black"); // Màu vàng, chữ đen
        break;
      default:
        break;
    }

    return <span style={style}>{text}</span>;
  };

  // Hàm để render trạng thái
  const renderStatus = (status) => {
    let text = status;
    let style = createBadgeStyle("#6c757d"); // Màu xám (secondary) mặc định

    switch (status) {
      case "open":
        text = "Mở";
        style = createBadgeStyle("#28a745"); // Màu xanh lá (success)
        break;
      case "close":
        text = "Đóng";
        style = createBadgeStyle("#dc3545"); // Màu đỏ (danger)
        break;
      case "reply":
        text = "Đã trả lời";
        style = createBadgeStyle("#007bff"); // Màu xanh dương (primary)
        break;
      default:
        break;
    }

    return <span style={style}>{text}</span>;
  };

  return (
    <CCard className="h-100 d-flex flex-column">
      <CCardHeader>Danh sách góp ý của bạn</CCardHeader>
      <CCardBody className="flex-grow-1 overflow-auto">
        {isLoading ? (
          <CSpinner />
        ) : (
          <CTable hover responsive>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>ID</CTableHeaderCell>
                <CTableHeaderCell>Loại</CTableHeaderCell>
                <CTableHeaderCell>Trạng thái</CTableHeaderCell>
                <CTableHeaderCell>ID Biển báo</CTableHeaderCell>
                <CTableHeaderCell>Hành động</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {feedbacks.map((feedback) => (
                <CTableRow key={feedback.id}>
                  <CTableDataCell>{feedback.id}</CTableDataCell>
                  <CTableDataCell>{renderType(feedback.type)}</CTableDataCell>
                  <CTableDataCell>
                    {renderStatus(feedback.status)}
                  </CTableDataCell>
                  <CTableDataCell>
                    {feedback.map_sign_id || "Không có ID"}
                  </CTableDataCell>
                  <CTableDataCell>
                    {/* Nút Xem chi tiết */}
                    <CButton
                      color="info"
                      size="sm"
                      className="me-2"
                      onClick={() => handleViewDetail(feedback.id)}
                      disabled={loadingView[feedback.id]}
                    >
                      {loadingView[feedback.id] ? (
                        <CSpinner size="sm" />
                      ) : (
                        "Trả lời"
                      )}
                    </CButton>
                    {/* Nút Đóng */}
                    <CButton
                      color="warning"
                      size="sm"
                      className="me-2"
                      onClick={() => handleClose(feedback.id)}
                      disabled={
                        feedback.status === "close" || loadingClose[feedback.id]
                      }
                    >
                      {loadingClose[feedback.id] ? (
                        <CSpinner size="sm" />
                      ) : (
                        "Đóng"
                      )}
                    </CButton>
                    {/* Nút Xóa */}
                    <CButton
                      color="danger"
                      size="sm"
                      onClick={() => handleDelete(feedback.id)}
                      disabled={loadingDelete[feedback.id]}
                    >
                      {loadingDelete[feedback.id] ? (
                        <CSpinner size="sm" />
                      ) : (
                        "Xóa"
                      )}
                    </CButton>
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        )}
      </CCardBody>

      {/* Modal Xem chi tiết */}
      <CModal
        visible={showDetailModal}
        onClose={() => setShowDetailModal(false)}
        size="lg"
      >
        <CModalHeader closeButton>
          <CModalTitle>Chi tiết góp ý</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {selectedFeedback && (
            <>
              <PerfectScrollbar
                ref={scrollbarRef}
                options={{ suppressScrollX: true, minScrollbarLength: 50 }}
                style={{ height: "300px", marginBottom: "1rem" }}
              >
                <div className="chat-container">
                  {selectedFeedback.detail && (
                    <ChatContent
                      content={selectedFeedback.detail}
                      currentUserId={currentUserId}
                    />
                  )}
                </div>
              </PerfectScrollbar>

              <hr className="my-3" />

              {selectedFeedback.status !== "close" ? (
                <CInputGroup>
                  <CFormInput
                    placeholder="Nhập nội dung trả lời..."
                    value={replyContent}
                    onChange={(e) => setReplyContent(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleSubmitReply();
                      }
                    }}
                    disabled={loadingReply}
                  />
                  <CButton
                    color="primary"
                    onClick={handleSubmitReply}
                    disabled={loadingReply}
                  >
                    {loadingReply ? (
                      <CSpinner size="sm" />
                    ) : (
                      <CIcon icon={cilSend} />
                    )}
                  </CButton>
                </CInputGroup>
              ) : (
                <div className="text-center text-muted">
                  Góp ý này đã được đóng. Không thể trả lời thêm.
                </div>
              )}
            </>
          )}
        </CModalBody>
      </CModal>
    </CCard>
  );
};

export default UserFeedback;

import React from "react";

const DistanceEffectSign = ({
  width = 150,
  height = 60,
  content,
  backgroundColor = "white",
  borderColor = "#000000",
  contentColor = "#000000",
  contentStrokeColor,
  contentStrokeWidth,
  isIcon = false,
}) => {
  // Tính toán kích thước và vị trí các phần tử
  const borderWidth = Math.max(2, Math.floor(Math.min(width, height) * 0.01));
  const fontSize = Math.floor(height * 0.4);

  // Tính toán kích thước và vị trí mũi tên
  const arrowWidth = width * 0.2;
  const arrowHeight = height * 0.8;
  const arrowY = (height - arrowHeight) / 2;

  // Tính toán kích thước và vị trí nội dung
  const contentWidth = width - 2 * arrowWidth - 2 * borderWidth;
  const contentHeight = height * 0.8;
  const contentX = arrowWidth + borderWidth;
  const contentY = (height - contentHeight) / 2;

  // Đường dẫn SVG cho mũi tên
  const arrowPath =
    "M 254.5,42.5 C 296.916,83.4155 338.916,124.915 380.5,167C 354.835,167.5 329.169,167.667 303.5,167.5C 303.5,267.833 303.5,368.167 303.5,468.5C 271.5,468.5 239.5,468.5 207.5,468.5C 207.5,368.167 207.5,267.833 207.5,167.5C 181.831,167.667 156.165,167.5 130.5,167C 172.035,125.632 213.368,84.1321 254.5,42.5 Z";

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        position: "relative",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
      >
        {/* Hình chữ nhật nền */}
        <rect
          x={borderWidth / 2}
          y={borderWidth / 2}
          width={width - borderWidth}
          height={height - borderWidth}
          fill={backgroundColor}
          stroke={borderColor}
          strokeWidth={borderWidth}
        />

        {/* Mũi tên bên trái */}
        <svg
          x={borderWidth}
          y={arrowY}
          width={arrowWidth}
          height={arrowHeight}
          viewBox="0 0 512 512"
        >
          <path d={arrowPath} fill={contentColor} />
        </svg>

        {/* Mũi tên bên phải */}
        <svg
          x={width - arrowWidth - borderWidth}
          y={arrowY}
          width={arrowWidth}
          height={arrowHeight}
          viewBox="0 0 512 512"
        >
          <path d={arrowPath} fill={contentColor} />
        </svg>

        {/* Nội dung (text hoặc icon) */}
        <foreignObject
          x={contentX}
          y={contentY}
          width={contentWidth}
          height={contentHeight}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: contentColor,
              fontSize: `${fontSize}px`,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {isIcon ? (
              content
            ) : (
              <span
                style={{
                  WebkitTextStroke: contentStrokeWidth
                    ? `${contentStrokeWidth}px ${contentStrokeColor}`
                    : "none",
                }}
              >
                {content}
              </span>
            )}
          </div>
        </foreignObject>
      </svg>
    </div>
  );
};

export default DistanceEffectSign;

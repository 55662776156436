import React from "react";
import { CNavLink, CImage } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilUser,
  cilSettings,
  cilHistory,
  cilMap,
  cilInfo,
  cilCommentSquare,
  cilSpeedometer,
} from "@coreui/icons";
import { NavLink } from "react-router-dom";

// CSS tùy chỉnh
const customStyles = `
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .remove-dot::before,
  .remove-dot::after,
  .remove-dot *::before,
  .remove-dot *::after {
    content: none !important;
  }
  .remove-dot::marker,
  .remove-dot *::marker {
    content: none !important;
    display: none !important;
  }
  .nav-item {
    list-style-type: none;
  }
  .menu-item {
    border-radius: 8px;
    margin: 0 8px;
  }
  .menu-item:hover, .menu-item.active {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .icon-container {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// Component UserSidebar
const UserSidebar = ({ isOpen }) => {
  // Cấu trúc menu cho user
  const menuItems = [
    {
      name: "THỐNG KÊ",
      icon: cilSpeedometer,
      path: "/user/dashboard",
    },
    {
      name: "NGƯỜI DÙNG",
      submenu: [
        { name: "Thông tin cá nhân", icon: cilUser, path: "/user/me" },
        {
          name: "Cài đặt tài khoản",
          icon: cilSettings,
          path: "/user/settings",
        },
        { name: "Lịch sử hoạt động", icon: cilHistory, path: "/user/history" },
      ],
    },
    {
      name: "BIỂN BÁO",
      submenu: [
        { name: "Biển báo của tôi", icon: cilMap, path: "/user/mapsigns" },
        { name: "Thông tin biển báo", icon: cilInfo, path: "/user/sign-info" },
      ],
    },
    {
      name: "HỖ TRỢ",
      submenu: [
        {
          name: "Gửi phản hồi",
          icon: cilCommentSquare,
          path: "/user/feedbacks",
        },
        { name: "Trợ giúp", icon: cilInfo, path: "/user/help" },
      ],
    },
  ];

  // Hàm render cho logo
  const renderLogo = () => (
    <div className="remove-dot p-2">
      <CNavLink
        href="/"
        className={`flex items-center justify-center ${
          isOpen ? "" : "h-16"
        } remove-dot`}
      >
        <CImage
          src={isOpen ? "/media/logos/logo.png" : "/media/logos/logo-icon.png"}
          height={isOpen ? 40 : 30}
          alt="Logo"
          className={isOpen ? "" : "mx-auto"}
        />
      </CNavLink>
    </div>
  );

  // Hàm render cho các mục menu
  const renderMenuItem = (item, index) => (
    <NavLink
      key={index}
      to={item.path}
      className={({ isActive }) => `
        menu-item flex items-center py-2 px-3 text-gray-300 hover:text-white transition-colors duration-200 mb-1
        ${isActive ? "active text-white" : ""}
      `}
      style={{ textDecoration: "none" }}
    >
      {({ isActive }) => (
        <>
          <div className="icon-container">
            <CIcon
              icon={item.icon}
              size="lg"
              className={isOpen ? "mr-3" : ""}
              style={{ color: isActive ? "white" : "#9ca3af" }}
            />
          </div>
          {isOpen && <span className="text-md">{item.name}</span>}
        </>
      )}
    </NavLink>
  );

  return (
    <>
      <style>{customStyles}</style>
      <div
        className={`bg-gray-900 h-screen ${
          isOpen ? "w-56" : "w-16"
        } transition-all duration-300 ease-in-out flex flex-col remove-dot`}
      >
        {renderLogo()}
        {/* Phần menu có thể cuộn */}
        <nav className="flex-1 overflow-y-auto hide-scrollbar remove-dot">
          {menuItems.map((category, categoryIndex) => (
            <div key={categoryIndex} className="remove-dot">
              {isOpen && category.name !== "THỐNG KÊ" && (
                <div className="mt-2 mb-2 px-4">
                  <span className="text-blue-300 font-semibold text-sm">
                    {category.name}
                  </span>
                </div>
              )}
              {category.submenu
                ? category.submenu.map((item, index) =>
                    renderMenuItem(item, `${categoryIndex}-${index}`)
                  )
                : renderMenuItem(category, categoryIndex)}
            </div>
          ))}
        </nav>
      </div>
    </>
  );
};

export default UserSidebar;

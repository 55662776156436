import React from "react";
import { useLocation } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/Info";
import MapIcon from "@mui/icons-material/Map";
import HelpIcon from "@mui/icons-material/Help";
import SettingsIcon from "@mui/icons-material/Settings";
import HistoryIcon from "@mui/icons-material/History";

const UserBreadcrumb = () => {
  const location = useLocation();

  // Định nghĩa các route và thông tin tương ứng
  const routes = {
    "/user/me": {
      name: "Thông tin cá nhân",
      icon: <InfoIcon fontSize="small" />,
    },
    "/user/mapsigns": {
      name: "Biển báo của tôi",
      icon: <MapIcon fontSize="small" />,
    },
    "/user/feedbacks": {
      name: "Hỗ trợ người dùng",
      icon: <HelpIcon fontSize="small" />,
    },
    "/user/settings": {
      name: "Cài đặt tài khoản",
      icon: <SettingsIcon fontSize="small" />,
    },
    "/user/history": {
      name: "Lịch sử hoạt động",
      icon: <HistoryIcon fontSize="small" />,
    },
  };

  // Lấy thông tin của route hiện tại
  const currentRoute = routes[location.pathname] || {
    name: "Trang cá nhân",
    icon: <PersonIcon fontSize="small" />,
  };

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      className="py-2 px-4 bg-gray-100 border-b border-gray-300"
    >
      {/* Breadcrumb cho Trang chủ */}
      <Link
        underline="none"
        color="inherit"
        href="/"
        className="flex items-center"
      >
        <HomeIcon fontSize="small" className="mr-1" />
        Trang chủ
      </Link>

      {/* Breadcrumb cho Trang cá nhân */}
      <Link
        underline="none"
        color="inherit"
        href="/user"
        className="flex items-center"
      >
        <PersonIcon fontSize="small" className="mr-1" />
        Trang cá nhân
      </Link>

      {/* Breadcrumb cho trang hiện tại */}
      <Typography color="text.primary" className="flex items-center">
        {currentRoute.icon}
        <span className="ml-1">{currentRoute.name}</span>
      </Typography>
    </Breadcrumbs>
  );
};

export default UserBreadcrumb;

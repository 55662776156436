import React, { useMemo, lazy, Suspense } from "react";

// Sử dụng lazy loading cho các component con
const RoundSignComponent = lazy(() => import("./RoundSignComponent"));
const TriangleSignComponent = lazy(() => import("./TriangleSignComponent"));
const RectangleSignComponent = lazy(() => import("./RectangleSignComponent"));
const LaneDirectionSignComponent = lazy(() =>
  import("./LaneDirectionSignComponent")
);
const TrafficLaneSignComponent = lazy(() =>
  import("./TrafficLaneSignComponent")
);
const SquareSignComponent = lazy(() => import("./SquareSignComponent"));

// Sử dụng React.memo để tránh re-render không cần thiết
const SignPreview = React.memo(({ sign, icons = [], size = 100 }) => {
  // Di chuyển tất cả useMemo lên đầu component
  const signTypeId = useMemo(
    () => parseInt(sign?.sign_type_id || sign?.sign_id),
    [sign]
  );

  const getCrossType = useMemo(() => {
    if (!sign?.has_diagonal_stripe) return null;
    switch (sign?.stripe_direction) {
      case "left-to-right":
        return "redCrossLeft";
      case "right-to-left":
        return "redCrossRight";
      case "both":
        return "redCrossBoth";
      case "parallel":
        return "multiBlackCross";
      default:
        return null;
    }
  }, [sign]);

  const getContent = useMemo(() => {
    if (sign?.content_type === "Icon" && sign?.icon_id) {
      const icon = icons.find((i) => i.id === parseInt(sign.icon_id));
      if (icon && icon.image_path) {
        return (
          <img
            src={`/media/icons/${icon.image_path}`}
            alt={icon.icon_name}
            style={{ maxWidth: "80%", maxHeight: "80%", objectFit: "contain" }}
          />
        );
      }
    }
    return sign?.content_number || "";
  }, [sign, icons]);

  const commonProps = useMemo(
    () => ({
      size: size,
      backgroundColor: sign?.background_color || "white",
      content: getContent,
      borderColor: sign?.has_border ? sign?.border_color : "transparent",
      contentColor: sign?.number_color || "black",
      contentStrokeColor: sign?.number_has_border
        ? sign?.number_border_color
        : "transparent",
      contentStrokeWidth: sign?.number_has_border ? 2 : 0,
      isIcon: sign?.content_type === "Icon",
      crossType: getCrossType,
    }),
    [sign, size, getContent, getCrossType]
  );

  const specialSignSize = useMemo(
    () => ({
      width: size * 1.5,
      height: size * 2.5,
    }),
    [size]
  );

  const SignComponent = useMemo(() => {
    if (!sign) return null;
    switch (signTypeId) {
      case 1:
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
        return RoundSignComponent;
      case 2:
        return TriangleSignComponent;
      case 3:
        return RectangleSignComponent;
      case 5:
        return LaneDirectionSignComponent;
      case 6:
        return TrafficLaneSignComponent;
      case 7:
        return SquareSignComponent;
      default:
        return RectangleSignComponent;
    }
  }, [signTypeId]);

  if (!sign) {
    return null;
  }

  if (isNaN(signTypeId)) {
    console.error("Invalid sign_type_id:", sign.sign_type_id);
    return null;
  }

  // Render component với Suspense để xử lý lazy loading
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SignComponent
        {...commonProps}
        width={
          signTypeId === 5 || signTypeId === 6 ? specialSignSize.width : size
        }
        height={
          signTypeId === 5 || signTypeId === 6
            ? specialSignSize.height
            : size * 1.2
        }
        lanes={sign.lane_count}
        laneIcons={
          Array.isArray(sign.lane_directions)
            ? sign.lane_directions
                .map((id) => icons.find((icon) => icon.id === parseInt(id)))
                .filter(Boolean)
            : []
        }
        laneSeperatorType={sign.lane_separator_type || "solid"}
        laneSeperatorColor={sign.lane_separator_color || "white"}
        hasSpeedLimits={sign.has_speed_limits}
        speedLimits={Array.isArray(sign.speed_limits) ? sign.speed_limits : []}
        vehiclesPerLane={
          Array.isArray(sign.vehicles_per_lane) ? sign.vehicles_per_lane : []
        }
        vehicleIcons={(Array.isArray(sign.vehicle_icons)
          ? sign.vehicle_icons
          : []
        ).map((laneIcons) =>
          Array.isArray(laneIcons)
            ? laneIcons
                .map((iconId) =>
                  icons.find((icon) => icon.id === parseInt(iconId))
                )
                .filter(Boolean)
            : []
        )}
      />
    </Suspense>
  );
});

export default SignPreview;

import React, { useState, useEffect, useCallback } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CSpinner,
  CAlert,
  CPagination,
  CPaginationItem,
  CFormInput,
  CFormSelect,
  CButton,
} from "@coreui/react";
import {
  getMyMapSigns,
  getSigns,
  getSubsigns,
  getIcons,
} from "../../../services/api";
import toast from "react-hot-toast";
import SignPreview from "../../trafficsigns/SignPreview";
import SubsignPreview from "../../subtrafficsigns/SubsignPreview";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const cellStyle = {
  verticalAlign: "middle",
  textAlign: "center",
};

const roadNameCellStyle = {
  ...cellStyle,
  maxWidth: "200px",
  whiteSpace: "normal",
  wordWrap: "break-word",
};

const UserMapSigns = () => {
  const [mapSigns, setMapSigns] = useState([]);
  const [filteredMapSigns, setFilteredMapSigns] = useState([]);
  const [signs, setSigns] = useState([]);
  const [subsigns, setSubsigns] = useState([]);
  const [icons, setIcons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  const [searchId, setSearchId] = useState("");
  const [filterRoadName, setFilterRoadName] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  // Hàm để xử lý và hiển thị road_name
  const formatRoadName = (roadNameObj) => {
    if (!roadNameObj) return "Không có thông tin";

    try {
      const parsedObj =
        typeof roadNameObj === "string" ? JSON.parse(roadNameObj) : roadNameObj;
      const order = ["26", "22", "16"]; // Thứ tự hiển thị: đường, quận, thành phố
      const formattedParts = order.map((key) => parsedObj[key]).filter(Boolean);

      return formattedParts.length > 0
        ? formattedParts.join(", ")
        : "Không có thông tin";
    } catch (error) {
      console.error("Lỗi khi xử lý dữ liệu tên đường:", error);
      return "Lỗi dữ liệu";
    }
  };

  const fetchMapSigns = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getMyMapSigns();
      if (response && Array.isArray(response)) {
        const sortedData = response.sort((a, b) => b.id - a.id);
        setMapSigns(sortedData);
        setFilteredMapSigns(sortedData);
        setTotalItems(sortedData.length);
      } else {
        throw new Error("Dữ liệu trả về không đúng định dạng");
      }
    } catch (error) {
      console.error("Lỗi khi tải danh sách biển báo:", error);
      setError("Không thể tải danh sách biển báo. Vui lòng thử lại sau.");
      toast.error("Lỗi khi tải danh sách biển báo");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchRelatedData = useCallback(async () => {
    try {
      const [signsResponse, subsignsResponse, iconsResponse] =
        await Promise.all([getSigns(), getSubsigns(), getIcons()]);
      setSigns(signsResponse.data || []);
      setSubsigns(subsignsResponse.data || []);
      setIcons(iconsResponse || []);
    } catch (error) {
      console.error("Lỗi khi tải dữ liệu liên quan:", error);
      toast.error("Không thể tải dữ liệu liên quan");
    }
  }, []);

  useEffect(() => {
    fetchMapSigns();
    fetchRelatedData();
  }, [fetchMapSigns, fetchRelatedData]);

  useEffect(() => {
    const filtered = mapSigns.filter(
      (sign) =>
        (searchId === "" || sign.id.toString().includes(searchId)) &&
        (filterRoadName === "" ||
          formatRoadName(sign.road_name)
            .toLowerCase()
            .includes(filterRoadName.toLowerCase())) &&
        (filterStatus === "" || sign.status === filterStatus)
    );
    setFilteredMapSigns(filtered);
    setTotalItems(filtered.length);
    setCurrentPage(1);
  }, [mapSigns, searchId, filterRoadName, filterStatus]);

  const paginatedMapSigns = filteredMapSigns.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const renderPagination = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    if (totalPages <= 1) return null;

    const pageNumbers = [];
    const ellipsis = <CPaginationItem key="ellipsis">...</CPaginationItem>;

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <CPaginationItem
            key={i}
            active={currentPage === i}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </CPaginationItem>
        );
      }
    } else {
      pageNumbers.push(
        <CPaginationItem
          key={1}
          active={currentPage === 1}
          onClick={() => setCurrentPage(1)}
        >
          1
        </CPaginationItem>
      );

      if (currentPage > 3) {
        pageNumbers.push(
          React.cloneElement(ellipsis, { key: "ellipsis-start" })
        );
      }

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <CPaginationItem
            key={i}
            active={currentPage === i}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </CPaginationItem>
        );
      }

      if (currentPage < totalPages - 2) {
        pageNumbers.push(React.cloneElement(ellipsis, { key: "ellipsis-end" }));
      }

      pageNumbers.push(
        <CPaginationItem
          key={totalPages}
          active={currentPage === totalPages}
          onClick={() => setCurrentPage(totalPages)}
        >
          {totalPages}
        </CPaginationItem>
      );
    }

    return (
      <CPagination align="center" aria-label="Page navigation example">
        <CPaginationItem
          key="previous"
          aria-label="Previous"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          <span aria-hidden="true">&laquo;</span>
        </CPaginationItem>
        {pageNumbers}
        <CPaginationItem
          key="next"
          aria-label="Next"
          disabled={currentPage === totalPages}
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
        >
          <span aria-hidden="true">&raquo;</span>
        </CPaginationItem>
      </CPagination>
    );
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "approve":
        return "success";
      case "pending":
        return "warning";
      case "reject":
        return "danger";
      case "remove":
        return "dark";
      default:
        return "primary";
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case "approve":
        return "Cho phép";
      case "pending":
        return "Đang chờ";
      case "reject":
        return "Từ chối";
      case "remove":
        return "Xóa bỏ";
      default:
        return status;
    }
  };

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "N/A";
    const date = new Date(dateTimeString);
    return date.toLocaleString("vi-VN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  return (
    <CCard className="h-100">
      <CCardBody className="d-flex flex-column">
        <CRow className="mb-3">
          <CCol md={4}>
            <CFormInput
              type="text"
              placeholder="Tìm kiếm theo ID"
              value={searchId}
              onChange={(e) => setSearchId(e.target.value)}
            />
          </CCol>
          <CCol md={4}>
            <CFormInput
              type="text"
              placeholder="Lọc theo tên đường"
              value={filterRoadName}
              onChange={(e) => setFilterRoadName(e.target.value)}
            />
          </CCol>
          <CCol md={4}>
            <CFormSelect
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="">Tất cả trạng thái</option>
              <option value="approve">Cho phép</option>
              <option value="pending">Đang chờ</option>
              <option value="reject">Từ chối</option>
              <option value="remove">Xóa bỏ</option>
            </CFormSelect>
          </CCol>
        </CRow>

        <div
          className="flex-grow-1 position-relative"
          style={{ height: "calc(100vh - 250px)" }}
        >
          {error && <CAlert color="danger">{error}</CAlert>}

          <PerfectScrollbar
            options={{ suppressScrollX: true, minScrollbarLength: 50 }}
          >
            <CTable responsive>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col" style={cellStyle}>
                    ID
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" style={roadNameCellStyle}>
                    Tên đường
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" style={cellStyle}>
                    Ngày tạo
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" style={cellStyle}>
                    Trạng thái
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" style={cellStyle}>
                    Biển báo
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" style={cellStyle}>
                    Biển phụ
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {paginatedMapSigns.length > 0 ? (
                  paginatedMapSigns.map((mapSign) => {
                    const sign = signs.find((s) => s.id === mapSign.sign_id);
                    const subsign = subsigns.find(
                      (s) => s.id === mapSign.subsign_id
                    );
                    return (
                      <CTableRow key={mapSign.id}>
                        <CTableDataCell style={cellStyle}>
                          {mapSign.id}
                        </CTableDataCell>
                        <CTableDataCell style={roadNameCellStyle}>
                          {formatRoadName(mapSign.road_name)}
                        </CTableDataCell>
                        <CTableDataCell style={cellStyle}>
                          {formatDateTime(mapSign.create_datetime)}
                        </CTableDataCell>
                        <CTableDataCell style={cellStyle}>
                          <CButton
                            color={getStatusColor(mapSign.status)}
                            shape="rounded-pill"
                            size="sm"
                          >
                            {getStatusText(mapSign.status)}
                          </CButton>
                        </CTableDataCell>
                        <CTableDataCell
                          style={{
                            ...cellStyle,
                            width: "100px",
                            height: "110px",
                          }}
                        >
                          {sign ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <SignPreview
                                sign={sign}
                                icons={icons}
                                size={80}
                              />
                            </div>
                          ) : (
                            "Không có biển báo"
                          )}
                        </CTableDataCell>
                        <CTableDataCell
                          style={{
                            ...cellStyle,
                            width: "100px",
                            height: "110px",
                          }}
                        >
                          {subsign ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <SubsignPreview subsign={subsign} icons={icons} />
                            </div>
                          ) : (
                            "Không có biển phụ"
                          )}
                        </CTableDataCell>
                      </CTableRow>
                    );
                  })
                ) : (
                  <CTableRow key="no-data">
                    <CTableDataCell colSpan="6" style={cellStyle}>
                      Không có dữ liệu
                    </CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>

            <div className="mt-3 d-flex justify-content-center">
              {renderPagination()}
            </div>
          </PerfectScrollbar>

          {isLoading && (
            <div className="position-absolute top-50 start-50 translate-middle">
              <CSpinner color="primary" />
            </div>
          )}
        </div>
      </CCardBody>
    </CCard>
  );
};

export default UserMapSigns;
